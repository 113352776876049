export const RegistrationsTypes = {
  REGISTRATIONS_LIST: "registrations/REGISTRATIONS_LIST",
  REGISTRATIONS_LIST_SUCCESS: "registrations/REGISTRATIONS_LIST_SUCCESS",

  GET_CURRENT_REGISTRATION: "registrations/GET_CURRENT_REGISTRATION",
  GET_CURRENT_REGISTRATION_SUCCESS: "registrations/GET_CURRENT_REGISTRATION_SUCCESS",
  CHANGE_REGISTRATION_STATUS: "registrations/CHANGE_REGISTRATION_STATUS",

  UPDATE_REGISTRATION: "registrations/UPDATE_REGISTRATION",
  EDIT_MEMBER: "registrations/EDIT_MEMBER",
  ADD_MEMBER: "registrationS/ADD_MEMBER",
  DELETE_MEMBER: "registrationS/DELETE_MEMBER",
  GET_HOTELS: "registration/GET_HOTELS",
  GET_HOTELS_SUCCESS: "registration/GET_HOTELS_SUCCESS",
  GET_SPORTS_INFO: "registrations/GET_SPORTS_INFO",
  GET_SPORTS_INFO_SUCCESS: "registrations/GET_SPORTS_INFO_SUCCESS",

  EXPORT_EXCEL: "registrations/EXPORT_EXCEL",
};

export const listRegistrations = (payload) => ({
  type: RegistrationsTypes.REGISTRATIONS_LIST,
  payload,
});

export const getCurrentRegistration = (payload) => ({
  type: RegistrationsTypes.GET_CURRENT_REGISTRATION,
  payload,
});

export const changeRegistrationStatus = (payload) => ({
  type: RegistrationsTypes.CHANGE_REGISTRATION_STATUS,
  payload,
});

export const updateRegistration = (payload) => ({
  type: RegistrationsTypes.UPDATE_REGISTRATION,
  payload,
});
export const editMember = (payload) => ({
  type: RegistrationsTypes.EDIT_MEMBER,
  payload,
});
export const addMember = (payload) => ({
  type: RegistrationsTypes.ADD_MEMBER,
  payload,
});
export const deleteMember = (payload) => ({
  type: RegistrationsTypes.DELETE_MEMBER,
  payload,
});
export const getHotels = (payload) => ({
  type: RegistrationsTypes.GET_HOTELS,
  payload,
});
export const getSportsInfo = (payload) => ({
  type: RegistrationsTypes.GET_SPORTS_INFO,
  payload,
});
export const exportExcel = (payload) => ({
  type: RegistrationsTypes.EXPORT_EXCEL,
  payload,
});
