/* eslint-disable */
import React, { useMemo } from "react";
import "./styles.scss";
import TextInput from "../TextInput/TextInput";
import Select from "react-select";
import countryList from "react-select-country-list";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 25 * 0.75,
    height: 40,
    display: "flex",
    border: "1px solid gray",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const TeamForm = ({ edit, payload, setPayload, hotels }) => {
  const options = useMemo(() => countryList().getData(), []);
  return (
    <div style={{ width: "100%" }}>
      <div className="user-form-container">
        <TextInput
          value={payload.team}
          setValue={(e) => {
            setPayload({ ...payload, team: e });
          }}
          label={"Team's name"}
          compulsory
          style={{ width: "20%", padding: "0px" }}
          inputStyle={{ height: "40px" }}
          disabled={!edit}
        />
        <TextInput
          value={payload.teamEmail}
          setValue={(e) => {
            setPayload({ ...payload, teamEmail: e });
          }}
          label={"Team's email"}
          compulsory
          style={{ width: "20%", padding: "0px" }}
          inputStyle={{ height: "40px" }}
          disabled={!edit}
        />
        <div className="input-container" style={{ width: "20%", padding: "1vw" }}>
          <label
            className="flex-container full-width"
            style={{
              fontSize: "13px",
              marginBottom: "1%",
            }}
          >
            Country
            <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
          </label>
          <Select
            options={options}
            value={{ value: payload.country, label: payload.country }}
            styles={customStyles}
            components={{ IndicatorSeparator: () => null }}
            onChange={(e) => {
              setPayload({ ...payload, country: e.label });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TeamForm;
