/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import { history } from "../../config/stores";

const ResultGrid = ({ type, categoryRecords }) => {
  const [scoreBoard, setScoreBoard] = useState([]);

  useEffect(() => {
    if (categoryRecords.length > 0) {
      setScoreBoard(categoryRecords?.[0]?._resultsFiles)
    }
  }, [categoryRecords]);

  return (
    <div className="result-table">
      <div className="result-table-header">
        <h5 className="result-category">{type}</h5>
      </div>
      <div
        className={`result-table-body ${
          scoreBoard?.length < 1
            ? "no-records"
            : null
        }`}
      >
        {scoreBoard?.length > 0 ? (
          <Scrollbars style={{ height: "100%" }}>
            {scoreBoard?.map((rec, i) => {
                return (
                <div className="result-wrapper">
                  <div className="left-wrapper">
                    <div className="image-wrapper flex">
                      <img src={require("../../assets/icons/link-blue-bgr.svg")} />
                    </div>
                    <div className="name-wrapper flex">
                      <p>{rec?.title || file?.url || 'File title'}</p>
                    </div>
                  </div>
                    <div className="right-wrapper image-wrapper flex"
                     onClick={() => {
                      history.push({
                      pathname: `/edit-record/${rec?._id}`,
                      state: rec,
                      });
                     }}>
                      <img src={require("../../assets/icons/edit.svg")} />
                    </div>
                </div>
                );
              })}
          </Scrollbars>
        ) : (
          <>
            <div className="table-empty-state" />
            <p>NO RESULT ADDED YET</p>
          </>
        )}
      </div>
      <div
        className="result-table-footer"
        onClick={() => history.push(`/add-record/${categoryRecords[0]?._id}`)}
      >
        <div className="add-record-container">
          <p className="add-record-prefix">+</p>
          <h5 className="add-record-text">ADD</h5>
        </div>
      </div>
    </div>
  );
};

export default ResultGrid;
