import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { authTypes } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { User } from "../utilites/User";
import { history } from "../config/stores";
import { toast } from "react-toastify";

export const loginRequestCodeEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN_REQUEST_CODE),
    switchMap(({ payload: { phoneNumber, onSuccess } }) => {
      return ajax({
        url: `${API_URL}/auth/request-code`,
        method: "POST",
        headers: Headers.get(),
        body: JSON.stringify({ phoneNumber }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err?.response?.message);
            obs.complete();
          });
        })
      );
    })
  );

export const loginSendCodeEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.LOGIN_SEND_CODE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/auth/use-code`,
        method: "POST",
        headers: Headers.get(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            User.authenticate(response.payload.token, {
              token: response.payload.token,
              ...response?.payload.user,
            });
            payload.onSuccess();
            obs.next({
              type: authTypes.LOGIN_SUCCESS,
              payload: response.payload,
            });
            if (response.payload.user.role === "captain") {
              history.push(`/registration/${response.payload.registrationId}`);
              localStorage.setItem("registrationID", response.payload.registrationId);
            } else history.push("/");
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err?.response?.message);
            obs.complete();
          });
        })
      );
    })
  );

export const logOutEpic = (action$) =>
  action$.pipe(
    ofType(authTypes.LOG_OUT),
    switchMap(() =>
      ActionsObservable.create((obs) => {
        User.signout();
        localStorage.clear();
        toast.success("Logout successfull");
        obs.next({
          type: authTypes.LOG_OUT_SUCCESS,
        });
      })
    )
  );
