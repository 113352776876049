/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { listRegistrations, exportExcel } from "../../actions";
import RegistrationsGrid from "../../components/Grids/RegistrationsGrid";
import { API_URL } from "../../config/settings";
import axios from "axios";
import { Headers } from "../../utilites/Headers";

const Registrations = ({ registrations, listRegistrations, exportExcel, totalPages, count }) => {
  const [currrentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    listRegistrations({
      page: currrentPage,
      limit: 20,
    });
    setCurrentPage(currrentPage + 1);
  }, []);

  return (
    <div className="main-body">
      <div className="shadow-body">
        <div className="flex-container full-width justify-end">
          <div
            className="export-icon"
            onClick={() => {
              axios({
                url: `${API_URL}/registrations/export`,
                method: "POST",
                responseType: "blob",
                headers: Headers.get_auth(),
              }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "file.xlsx"); //or any other extension
                document.body.appendChild(link);
                link.click();
              });
            }}
          />
        </div>
        <RegistrationsGrid
          regs={registrations}
          list={listRegistrations}
          curPage={currrentPage}
          setCurPage={setCurrentPage}
          totalPages={totalPages}
        />
        <div className="stats-container">
          <p className="stat">
            Participants:
            <b style={{ paddingLeft: "7px" }}>{count}</b>
          </p>
          <div className="divider" />
          <p className="stat">
            Teams:<b style={{ paddingLeft: "7px" }}>{"Yet unknown"}</b>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  registrations: state.regs.registrations,
  totalPages: state.regs.totalPages,
  count: state.regs.count,
});

const mapDispatchToProps = (dispatch) => ({
  listRegistrations: (payload) => dispatch(listRegistrations(payload)),
  exportExcel: (payload) => dispatch(exportExcel(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Registrations);
