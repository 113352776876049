/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import Button from "../Button/Button";
import { history } from "../../config/stores";
import { Whisper, Popover } from "rsuite";
import moment from "moment";

const headerElements = [
  {
    name: "Names",
    query: { filter: "name", options: [] },
  },
  {
    name: "Gender",
    query: { filter: "_phone", options: [] },
  },
  {
    name: "Date of birth",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Address",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Email address",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Nationality",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Height",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Shoe size",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "T-shirt size",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Competing in",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Renting out equipment",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Hotel type",
    query: { filter: "_addedOn", options: [] },
  },
  {
    name: "Room type",
    query: { filter: "_addedOn", options: [] },
  },
];

const MembersGrid = ({ members, bigger, regID }) => {
  const [sortedMembers, setSortedMembers] = useState([]);

  useEffect(() => {
    const newMembers = members?.sort((a, b) => Number(b.isTeamLeader) - Number(a.isTeamLeader));
    setSortedMembers(newMembers);
  }, [members]);

  return (
    <Scrollbars
      style={{
        height:"44vh",
        marginTop: "1%",
        width: "94vw",
      }}
    >
      <div className="table-header semi-bigger">
        {headerElements.map((header, i) => {
          return (
            <div className="header-cont start" key={i} style={{ width: i === 4 ? "16%" : i === 10 ? "18%" : i >= 11 && "22%" }}>
              <div className="header">{header.name}</div>
              {/*<BiChevronDown size={"1.3em"} cursor={"pointer"} /> */}
            </div>
          );
        })}
      </div>
      {sortedMembers?.map((member, i) => {
        return (
          <React.Fragment key={i}>
            <div className="table-body-row semi-bigger">
              <div className="row-data">{member.fullName}</div>
              <div className="row-data">{member?.gender?.length > 0 ? member?.gender : "---"}</div>
              <div className="row-data">{moment(member.birthdate).format("DD.MM.YYYY")}</div>
              <div className="row-data">{member.address || "---"}</div>
              <div className="row-data" style={{ width: "16%" }}>
                {member.email}
              </div>
              <div className="row-data">{member.nationality || "---"}</div>
              <div className="row-data">{member.height || "---"}</div>
              <div className="row-data">{member.shoeSize || "---"}</div>
              <div className="row-data">{member.shirtSize || "---"}</div>
              <div className="row-data">
                {member.competitions?.map((comp, i) => (i === member.competitions.length - 1 ? ` ${comp}` : ` ${comp} |`))}
              </div>
              <div className="row-data" style={{ width: "17%" }}>
                {member.equipment?.slice(0, 2)?.map((eq, i) => (i === 1 ? ` ${eq}` : `${eq} |`))}
                {member?.equipment?.length > 1 && (
                  <Whisper
                    placement="bottomStart"
                    trigger="hover"
                    controlId="control-id-hover"
                    speaker={
                      <Popover>
                        {member.equipment?.slice(2, member.equipment.length)?.map((eq, i) => {
                          return <p style={{ textTransform: "capitalize", paddingBottom: "2px" }}>{eq}</p>;
                        })}
                      </Popover>
                    }
                  >
                    <div className="info-icon" />
                  </Whisper>
                )}
              </div>
              <div className="row-data" style={{ width: "22%" }}>
                {member?.hotel || "---"}
              </div>
              <div className="row-data" style={{ width: "22%" }}>
                <div className="flex-container full-width space-between">
                  {member?.singleRoomCount
                    ? "Single"
                    : member?.doubleRoomCount
                    ? "Double"
                    : member?.tripleRoomCount
                    ? "Apartment for 3 people"
                    : member?.quadrupleRoomCount
                    ? "Apartment for 4 people"
                    : "none"}
                  <div className="edit-icon pointer" onClick={() => history.push(`/edit-member/${regID}/${member?._id}`)} />
                </div>
              </div>
            </div>
            {member.isTeamLeader && <div className="team-leader-cont">TEAM LEADER</div>}
          </React.Fragment>
        );
      })}
      <Button
        dotted
        text="Add a team member"
        style={{ width: "140%", marginTop: "2%", marginBottom: "20px" }}
        onClick={() => {
          history.push(`/add-member/${regID}`);
        }}
      />
    </Scrollbars>
  );
};

export default MembersGrid;
