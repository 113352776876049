/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { getCategories, getCategoryRecords, addRecord } from "../../actions";
import "./styles.scss";
import Button from "../../components/Button/Button";
import ResultGrid from "../../components/Grids/ResultsGrid";

const Results = ({
  categories,
  getCategories,
  categoryRecords,
  getCategoryRecords,
  addRecord,
}) => {
  const [selOption, setSelOption] = useState();

  useEffect(() => {
    getCategories({
      onSuccess: (res) => {
        setSelOption(res[0]);
        getCategoryRecords(res[0]._id);
      },
    });
  }, []);

  return (
    <div className="main-body">
      <div className="shadow-body no-shadow" style={{height:"unset"}}>
        <div className="shadow-body-header">
          <div className="left-shadow-body-header">
            {categories?.map((cat, i) => {
              return (
                <Button
                  gray={selOption?.title !== cat.title && true}
                  selected={selOption?.title === cat.title && true}
                  text={cat.title}
                  key={i}
                  style={{
                    width: "15%",
                    marginLeft: i > 0 && "2%",
                    height: "60px",
                  }}
                  textStyle
                  onClick={() => {
                    setSelOption(cat);
                    getCategoryRecords(cat._id);
                  }}
                />
              );
            })}
          </div>
          {/* <div className="right-shadow-body-header">
            <Button
              text="Publish"
              onClick={() => {}}
              blue
              style={{ width: "45%", marginTop: "-10px" }}
            />
          </div> */}
        </div>
      </div>
      <div className="shadow-body results-body">
        {selOption?.title === 'COMBINATION CUP' ?
        <>
          <ResultGrid
          type="women"
          categories={categories}
          categoryRecords={categoryRecords?.filter(
            (rec) => rec?.title === "WOMEN"
          )}
        />
        <ResultGrid
          type="men"
          categories={categories}
          categoryRecords={categoryRecords?.filter(
            (rec) => rec?.title === "MEN"
          )}
        />
        </> : selOption?.title === 'GFL CUP' ?
        <ResultGrid
          type="overall results"
          categories={categories}
          categoryRecords={categoryRecords?.filter(
            (rec) => rec?.title === "OVERALL RESULTS"
          )}
        /> :
        <>
          <ResultGrid
          type="general"
          categories={categories}
          categoryRecords={categoryRecords?.filter(
            (rec) => rec?.title === "GENERAL"
          )}
        />
        <ResultGrid
          type="senior"
          categories={categories}
          categoryRecords={categoryRecords?.filter(
            (rec) => rec?.title === "SENIOR"
          )}
        />
        <ResultGrid
          type="super senior"
          categories={categories}
          categoryRecords={categoryRecords?.filter(
            (rec) => rec?.title === "SUPER SENIOR"
          )}
        />
        </>
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.results.categories,
  categoryRecords: state.results.categoryRecords,
});

const mapDispatchToProps = (dispatch) => ({
  getCategories: (payload) => dispatch(getCategories(payload)),
  getCategoryRecords: (payload) => dispatch(getCategoryRecords(payload)),
  addRecord: (payload) => dispatch(addRecord(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Results);
