/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { getCurrentRegistration, changeRegistrationStatus, updateRegistration, getHotels, getSportsInfo } from "../../actions";
import Button from "../../components/Button/Button";
import { history } from "../../config/stores";
import MembersGrid from "../../components/Grids/MembersGrid";
import Swal from "sweetalert2";
import { Dropdown, IconButton } from "rsuite";
import CloseIcon from "@rsuite/icons/Close";
import "rsuite/dist/rsuite.min.css";
import RejectRegModal from "../../components/ModalComponent/RejectRegModal";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import { FiMoreVertical } from "react-icons/fi";
import { Icon } from "@rsuite/icons";
import TeamForm from "../../components/Forms/TeamForm";
import { toast } from "react-toastify";
import { User } from "../../utilites/User";

const renderIconButton = (props, ref) => {
  return (
    <IconButton
      {...props}
      ref={ref}
      icon={<Icon as={FiMoreVertical} size="1.5em" style={{ color: "#000000" }} />}
      appearance="subtle"
      size="lg"
    />
  );
};

const OpenedRegistration = ({
  currentRegistration,
  getCurrentRegistration,
  changeRegistrationStatus,
  updateRegistration,
  getHotels,
  hotels,
  getSportsInfo,
  sportsInfo,
}) => {
  const reg_ID = window.location.href.split("/")[4];
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");
  const [editTeamForm, setEditTeamForm] = useState(false);
  const [payments, setPayments] = useState({
    first: false,
    second: false,
  });
  const [payload, setPayload] = useState({
    team: "",
    teamEmail: "",
    country: "",
    hotel: "",
    singleRoomCount: 0,
    doubleRoomCount: 0,
    tripleRoomCount: 0,
    quadrupleRoomCount: 0,
    firstPayment: false,
    secondPayment: false,
  });

  useEffect(() => {
    getCurrentRegistration({
      _id: reg_ID,
      onSuccess: (res) => {
        setPayload({
          team: res.team,
          teamEmail: res.teamEmail,
          country: res.country,
          hotel: res.hotel,
          singleRoomCount: res.singleRoomCount,
          doubleRoomCount: res.doubleRoomCount,
          tripleRoomCount: res.tripleRoomCount,
          quadrupleRoomCount: res.quadrupleRoomCount,
          firstPayment: res.firstPayment.isPaid,
          secondPayment: res.secondPayment.isPaid,
        });
        setPayments({
          ...payments,
          ["first"]: res.firstPayment.amount,
          ["second"]: res.secondPayment.amount,
        });
      },
    });
    getHotels();
    getSportsInfo();
  }, []);

  console.log(currentRegistration);
  return (
    <div className="main-body">
      <div className="shadow-header flex-container column justify-center height-unset">
        <div className="flex-container full-width space-between">
          <div className="head-cont">
            <div className="close" onClick={() => history.push("/")} />
            <h3 className="shadow-body-title">{currentRegistration?.team}</h3>
            <h3
              style={{
                textTransform: "uppercase",
                marginLeft: "3%",
                fontSize: "18px",
                marginTop: "2px",
                color:
                  currentRegistration?.status === "waiting"
                    ? "#B0D1FA"
                    : currentRegistration?.status === "accepted"
                    ? "#00e879"
                    : currentRegistration?.status === "canceled"
                    ? "#041B29"
                    : "#e5004c",
              }}
            >
              <b>{currentRegistration?.status}</b>
            </h3>
          </div>

          <div
            className={`flex-container align-center justify-end`}
            style={{
              width: editTeamForm ? "30%" : "20%",
            }}
          >
            {editTeamForm ? (
              <div className="flex-container space-around" style={{ width: "60%" }}>
                <Button
                  text={"CANCEL"}
                  style={{ width: "40%", height: "40px", marginRight: "3%" }}
                  redBorder
                  onClick={() => setEditTeamForm(false)}
                />
                <Button
                  text={"SAVE"}
                  style={{ width: "40%", height: "40px", marginRight: "1%" }}
                  green
                  onClick={() => {
                    console.log(payload);
                    updateRegistration({
                      _id: reg_ID,
                      ...payload,
                      onSuccess: () => {
                        getCurrentRegistration({
                          _id: reg_ID,
                          onSuccess: (res) => {
                            setPayments({
                              ...payments,
                              ["first"]: res.firstPayment.amount,
                              ["second"]: res.secondPayment.amount,
                            });
                            setEditTeamForm(false);
                            toast.success("Registration updated successfully!");
                          },
                        });
                      },
                    });
                  }}
                />
              </div>
            ) : User.isAdmin ? (
              <Dropdown renderToggle={renderIconButton} size="sm" placement="bottomEnd">
                <Dropdown.Item className="dropdown-item" icon={<CloseIcon size={"5em"} color="red" />} onClick={() => setOpenModal(true)}>
                  Cancel Registration
                </Dropdown.Item>
                <p
                  style={{
                    marginLeft: "5%",
                    fontSize: "13px",
                    paddingBottom: "0px",
                  }}
                >
                  If you cancel the registration, you have to enter the reason
                </p>
                <Dropdown.Item divider style={{ backgroundColor: "#0083E5" }} />
                <Dropdown.Item
                  className="dropdown-item"
                  onClick={() => {
                    setEditTeamForm(true);
                  }}
                >
                  <div className="edit-icon" /> Edit information
                </Dropdown.Item>
                <p
                  style={{
                    marginLeft: "5%",
                    fontSize: "13px",
                    paddingBottom: "0px",
                  }}
                >
                  Edit the information provided by the team
                </p>
              </Dropdown>
            ) : (
              <Button text={"EDIT"} style={{ width: "40%", height: "40px", marginRight: "1%" }} selected onClick={() => setEditTeamForm(true)} />
            )}
          </div>
        </div>
        {editTeamForm ? (
          <TeamForm name={reason} setName={setReason} edit={true} hotels={hotels} payload={payload} setPayload={setPayload} />
        ) : (
          <>
            <div
              className="flex-container full-width"
              style={{
                marginTop: "1%",
                height: "30px",
              }}
            >
              <p className="reg-info regular smaller" style={{ marginLeft: "0%" }}>
                Country
              </p>
              <p className="reg-info regular" style={{ width: "15%" }}>
                Team email
              </p>
              <p className="reg-info regular">Participants</p>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <p className="reg-info" style={{ marginLeft: "0%", textTransform: "capitalize" }}>
                {currentRegistration?.country || "---"}
              </p>
              <p className="reg-info" style={{ width: "15%" }}>
                {currentRegistration?.teamEmail || "---"}
              </p>
              <p className="reg-info">{currentRegistration?.members?.length || "---"}</p>
            </div>
          </>
        )}
      </div>
      {currentRegistration?.status === "accepted" && User.isAdmin ? (
        <div className="shadow-body" style={{ marginTop: "2%", padding: "0.1vw", height: "130px" }}>
          <div className="team-members-title flex-container space-between">
            <b style={{ width: "60px" }}></b>
            <b>PAYMENT INFO</b>
            <b style={{ marginRight: "1%" }}>{currentRegistration?.totalPrice ? currentRegistration?.totalPrice + " EUR" : "---"}</b>
          </div>
          <div className="payment-parts-cont">
            <div className="payment">
              <div
                className="flex-container justify-center full-width"
                style={{
                  paddingBottom: "15px",
                  paddingTop: "10px",
                }}
              >
                {editTeamForm && (
                  <input
                    type="checkbox"
                    name="switch"
                    className="check"
                    checked={payload.firstPayment ? true : false}
                    onChange={() => {}}
                    onClick={() => {
                      if (!payload.firstPayment) {
                        setPayload({
                          ...payload,
                          firstPayment: true,
                        });
                      } else {
                        setPayload({
                          ...payload,
                          firstPayment: false,
                        });
                      }
                    }}
                  />
                )}
                <p>First payment due - {payments?.first} EUR</p>
              </div>

              <div className={`indicator ${payload?.firstPayment && "selected"}`} />
            </div>
            <div className="payment">
              <div
                className="flex-container justify-center full-width"
                style={{
                  paddingBottom: "15px",
                  paddingTop: "10px",
                }}
              >
                {editTeamForm && (
                  <input
                    type="checkbox"
                    name="switch"
                    className="check"
                    checked={payload.secondPayment ? true : false}
                    onChange={() => {}}
                    onClick={() => {
                      if (!payload.secondPayment) {
                        setPayload({
                          ...payload,
                          secondPayment: true,
                        });
                      } else {
                        setPayload({
                          ...payload,
                          secondPayment: false,
                        });
                      }
                    }}
                  />
                )}
                <p>Second payment due - {payments?.second} EUR</p>
              </div>
              <div className={`indicator ${payload?.secondPayment && "selected"}`} />
            </div>
          </div>
        </div>
      ) : null}
      <div className="shadow-body" style={{ marginTop: "1%", padding: "0.1vw", height: "unset" }}>
        <div className="team-members-title">
          <b style={{ width: "95px" }}></b>
          <b>ТЕАМ MEMBERS</b>
          <b style={{ marginRight: "1%" }}>{currentRegistration?.members?.length} MEMBERS</b>
        </div>
        <MembersGrid members={currentRegistration?.members} bigger={currentRegistration?.status !== "accepted" && true} regID={reg_ID} />
      </div>
      <ModalComponent
        open={openModal}
        children={
          <RejectRegModal
            handleClose={() => {
              setOpenModal(false);
              setReason("");
            }}
            handleAccept={() => {
              changeRegistrationStatus({
                _id: reg_ID,
                status: "canceled",
                reason: reason,
                onSuccess: () => {
                  getCurrentRegistration({
                    _id: reg_ID,
                    onSuccess: (res) => {},
                  });
                  setOpenModal(false);
                  Swal.fire({
                    title: "<h5>SUCCESSFULLY CANCELLED</h5>",
                    icon: "success",
                    text: "This registration has been cancelled successfully!",
                    confirmButtonText: "CONTINUE",
                    confirmButtonColor: "#0083E5",
                  });
                },
              });
            }}
            value={reason}
            setValue={setReason}
          />
        }
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  currentRegistration: state.regs.currentRegistration,
  hotels: state.regs.hotels,
  sportsInfo: state.regs.sportsInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentRegistration: (payload) => dispatch(getCurrentRegistration(payload)),
  getHotels: (payload) => dispatch(getHotels(payload)),
  changeRegistrationStatus: (payload) => dispatch(changeRegistrationStatus(payload)),
  updateRegistration: (payload) => dispatch(updateRegistration(payload)),
  getSportsInfo: () => dispatch(getSportsInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpenedRegistration);
