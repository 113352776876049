/* eslint-disable */
import { authTypes, UsersTypes } from "../actions";

const initialState = {
  token: null,
  user: {},
  users: [],
  selectedUser: {},
};

const login = (state = initialState, { type, payload }) => {
  switch (type) {
    case authTypes.LOGIN_SUCCESS:
      return { ...state, user: payload.user, token: payload.token };

    case authTypes.LOGIN_SUCCESS_LOCAL_STORAGE: {
      return { ...state, user: payload, token: payload?.token };
    }
    case authTypes.LOAD_CREDENTIALS:
      return { ...state, ...payload };
    case UsersTypes.LIST_USERS_SUCCESS:
      return { ...state, users: payload };
    case UsersTypes.GET_USER_BY_ID_SUCCESS:
      return { ...state, selectedUser: payload };
    case authTypes.LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default login;
