/* eslint-disable */
import { RegistrationsTypes } from "../actions";

const initialState = {
  registrations: [],
  currentRegistration: {},
  hotels: [],
  sportsInfo: [],
  totalPages: 1,
  count: 1,
};

const handleNewRegistrations = (state, payload) => {
  const newState = { ...state };
  let notFound = true;
  if (newState.registrations.length > 0) {
    if (payload.page === 1) {
      newState.registrations = payload?.docs;
      return newState.registrations;
    }

    newState.registrations.map((oldReg) => {
      if (oldReg?._id?.includes(payload?.docs[0]?._id)) notFound = false;
    });

    if (!notFound) return newState.registrations;
    if (notFound) {
      newState.registrations = [...newState.registrations, ...payload?.docs];
      return newState.registrations;
    }
  } else if (payload.totalDocs === payload.limit) {
    return payload.docs;
  } else {
    newState.registrations = [...newState.registrations, ...payload?.docs];
    console.log(newState);
    return newState.registrations;
  }
  return newState.registrations;
};

const regs = (state = initialState, { type, payload }) => {
  switch (type) {
    case RegistrationsTypes.REGISTRATIONS_LIST_SUCCESS:
      return {
        ...state,
        totalPages: payload.totalPages,
        count: payload.totalDocs,
        registrations: handleNewRegistrations(state, payload),
      };
    case RegistrationsTypes.GET_CURRENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        currentRegistration: payload,
      };
    case RegistrationsTypes.GET_HOTELS_SUCCESS:
      return {
        ...state,
        hotels: payload,
      };
    case RegistrationsTypes.GET_SPORTS_INFO_SUCCESS:
      return {
        ...state,
        sportsInfo: payload,
      };
    default:
      return state;
  }
};

export default regs;
