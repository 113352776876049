import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { ResultsTypes } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

export const getCategoriesEpic = (action$) =>
  action$.pipe(
    ofType(ResultsTypes.GET_CATEGORIES),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/results/categories`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ResultsTypes.GET_CATEGORIES_SUCCESS,
              payload: response.payload,
            });
            payload.onSuccess(response.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getCategoryRecordsEpic = (action$) =>
  action$.pipe(
    ofType(ResultsTypes.GET_CATEGORY_RECORDS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/results/categories/${payload}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ResultsTypes.GET_CATEGORY_RECORDS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const addRecordEpic = (action$) =>
  action$.pipe(
    ofType(ResultsTypes.ADD_RECORD),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/results/results-files`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            console.log(response);
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const deleteRecordEpic = (action$) =>
  action$.pipe(
    ofType(ResultsTypes.DELETE_RECORD),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/results/results-files/${payload._id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const editRecordEpic = (action$) =>
  action$.pipe(
    ofType(ResultsTypes.UPDATE_RECORD),
    switchMap(({ payload: { resultsFileId, title, url, onSuccess } }) => {
      return ajax({
        url: `${API_URL}/results/results-files/${resultsFileId}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: {
          resultsFileId: resultsFileId,
          title: title,
          url: url
        },
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            onSuccess(response);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getAutocompleteEpic = (action$) =>
  action$.pipe(
    ofType(ResultsTypes.GET_AUTOCOMPLETE),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/members/autocomplete?pattern=${payload}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: ResultsTypes.GET_AUTOCOMPLETE_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );
