export const nationalities = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Armenian",
  "Austrian",
  "Azerbaijani",
  "Argentinian",
  "Australian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Belarusian",
  "Belizean",
  "Beninese",
  "Bermudian",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Botswanan",
  "British",
  "Bulgarian",
  "Burkinese",
  "Belgian",
  "Burundian",
  "Canadian",
  "Chinese",
  "Colombian",
  "Cuban",
  "Cambodian",
  "Cameroonian",
  "Cape Verdean",
  "Chadian",
  "Chilean",
  "Congolese",
  "Costa Rican",
  "Croat",
  "Cypriot",
  "Czech",
  "Danish",
  "Dominican",
  "Djiboutian",
  "Dominican",
  "Dutch",
  "Ecuadorean",
  "Egyptian",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Finnish",
  "French Polynesian",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Guatemalan",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Honduran",
  "Hungarian",
  "Indian",
  "Ireland",
  "Israeli",
  "Italian",
  "Icelandic",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Japanese",
  "Jamaican",
  "Jordanian",
  "Kazakh",
  "Kenyan",
  "North Korean",
  "Kuwaiti",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Lithuanian",
  "LUXEMBOURG",
  "Madagascan",
  "Malawian",
  "Malaysian",
  "Maldivian",
  "Malian",
  "Maltese",
  "Mauritanian",
  "Mauritian",
  "Monacan",
  "Mongolian",
  "Montenegrin",
  "Moroccan",
  "Mozambican",
  "Mexican",
  "Namibian",
  "Nepalese",
  "New Zealand",
  "Nicaraguan",
  "Nigerien",
  "Nigerian",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Panamanian",
  "Guinean",
  "Paraguayan",
  "Peruvian",
  "Philippine",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Salvadorean",
  "Samoan",
  "Saudi Arabian",
  "Senegalese",
  "Serbian",
  "Sierra Leonian",
  "Singaporean",
  "Slovak",
  "Slovenian",
  "Slomoni",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Swedish",
  "Swiss",
  "Sri Lankan",
  "Sudanese",
  "Surinamese",
  "Swazi",
  "Taiwanese",
  "Tajik",
  "Thai",
  "Togolese",
  "Trinidadian",
  "Tunisian",
  "Turkish",
  "Turkoman",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Emirati",
  "Venezuelan",
  "Vietnamese",
  "Uruguayan",
  "Uzbek",
  "Vanuatuan",
  "Yemeni",
  "Zambian",
];
export const shoeSizes = [
  "35 EU",
  "36 EU",
  "37 EU",
  "38 EU",
  "39 EU",
  "40 EU",
  "41 EU",
  "42 EU",
  "43 EU",
  "44 EU",
  "45 EU",
  "46 EU",
  "47 EU",
  "48 EU",
  "49 EU",
  "50 EU",
  "51 EU",
  "52 EU",
];

export const shirtSizes = [
  "XS",
  "S",
  "M",
  "L",
  "XL",
  "XXL",
  "XXL"
];
export const competitions = ["Ski", "Snowboard", "Ski-running"];

export const equipment = [
  "Skis",
  "Snowboard",
  "Ski boots",
  "Helmet",
  "Goggles",
  "Ski poles",
  "Sunglasses",
];
