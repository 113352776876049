/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import TextInput from "../TextInput/TextInput";

const RejectRegModal = ({ handleClose, handleAccept, value, setValue }) => {
  return (
    <div
      className={`delete-modal-container`}
      style={{ height: "300px", paddingTop: "5px" }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <h4 style={{ width: "95%", textAlign: "center" }}>
          CANCEL REGISTRATION
        </h4>
        <div className="close-icon" onClick={() => handleClose()} />
      </div>
      <p style={{ textAlign: "center", width: "90%", paddingBottom: "0px" }}>
        To cancel this registration you have to enter the reason. Please note
        that the participants will receive an email.
      </p>
      <TextInput
        value={value}
        setValue={setValue}
        label="Reason"
        style={{ marginTop: "-4%", padding: "1vw" }}
        inputStyle={{ width: "99%" }}
      />
      <div style={{ display: "flex", width: "93%" }}>
        <Button
          text={"CANCEL REGISTRATION"}
          style={{ width: "100%" }}
          red
          disabled={value?.length > 3 ? false : true}
          onClick={() => handleAccept()}
        />
      </div>
    </div>
  );
};
export default RejectRegModal;
