export const ResultsTypes = {
  GET_CATEGORIES: "categories/GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "categories/GET_CATEGORIES_SUCCESS",

  GET_CATEGORY_RECORDS: "categories/GET_CATEGORY_RECORDS",
  GET_CATEGORY_RECORDS_SUCCESS: "categories/GET_CATEGORY_RECORDS_SUCCESS",

  GET_AUTOCOMPLETE: "categories/GET_AUTOCOMPLETE",
  GET_AUTOCOMPLETE_SUCCESS: "categories/GET_AUTOCOMPLETE_SUCCESS",

  ADD_RECORD: "categories/ADD_RECORD",
  UPDATE_RECORD: "categories/UPDATE_RECORD",
  DELETE_RECORD: "categories/DELETE_RECORD",
};

export const getCategories = (payload) => ({
  type: ResultsTypes.GET_CATEGORIES,
  payload,
});

export const getCategoryRecords = (payload) => ({
  type: ResultsTypes.GET_CATEGORY_RECORDS,
  payload,
});

export const getAutocomplete = (payload) => ({
  type: ResultsTypes.GET_AUTOCOMPLETE,
  payload,
});

export const addRecord = (payload) => ({
  type: ResultsTypes.ADD_RECORD,
  payload,
});

export const updateRecord = (payload) => ({
  type: ResultsTypes.UPDATE_RECORD,
  payload,
});

export const deleteRecord = (payload) => ({
  type: ResultsTypes.DELETE_RECORD,
  payload,
});
