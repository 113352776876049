/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import PhoneInput from "../../components/PhoneInput/PhoneInput";
import { loginRequestCode, loginSendCode } from "../../actions";
import Button from "../../components/Button/Button";
import LoginVerification from "../../components/LoginVerification/LoginVerification";
import { toast } from "react-toastify";

const Home = ({ loginRequestCode, loginSendCode }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [changeScreen, setChangeScreen] = useState(false);
  const [code, setCode] = useState(new Array(4).fill(""));
  const [codeState, setCodeSate] = useState(null);

  console.log(phoneNumber);

  return (
    <div className="login-page">
      <div className="login-container">
        <div className="logo" />
        <h3 className="login-inner-header txt-center">Login to the system</h3>
        <p className="login-text txt-center">Login with the phone number you are registered with</p>
        {changeScreen ? (
          <>
            <h4 className="txt-center" style={{paddingTop:"10px"}}>{phoneNumber[0] !== 0 ? `+${phoneNumber}` : phoneNumber}</h4>
            <LoginVerification
              code={code}
              setCode={(value) => setCode(value)}
              codeState={codeState}
              onSendAgain={() => {
                setCode(new Array(4).fill(""));
              }}
            />
            <div
              className="last-container"
              style={{
                height: "10%",
                width: "100%",
                justifyContent: "center",
                marginLeft: "0%",
                alignItems: "center",
              }}
            >
              <Button
                text="LOGIN"
                style={{ width: "90%" }}
                disabled={code.length < 4 && true}
                onClick={() => {
                  loginSendCode({
                    phoneNumber: phoneNumber[0] === "+" ? `${phoneNumber}` : `+${phoneNumber}`,
                    code: code.join(""),
                    onSuccess: () => {
                      toast.success("Login successfully");
                    },
                  });
                }}
              />
            </div>
            <p className="login-text txt-center">
              Wrong number?{" "}
              <b
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  setChangeScreen(false);
                  setCode(new Array(4).fill(""));
                }}
              >
                Enter a new phone number
              </b>
            </p>
          </>
        ) : (
          <>
            <PhoneInput
              value={phoneNumber}
              setValue={setPhoneNumber}
              compulsory
              labelStyle={{ display: "none" }}
              style={{ width: "92%", marginTop: "5%", marginLeft: "4%" }}
            />
            <p className="txt-center" style={{fontSize:"13px",padding:"10px 0px"}}>Please use the log in info, sent to you by the GFL team.</p>
            <div className="last-container">
              <Button
                text="CONTINUE"
                style={{ height: "40px", width: "90%" }}
                disabled={phoneNumber.length < 7 && true}
                onClick={() => {
                  if (phoneNumber.length > 4 && phoneNumber.length < 19)
                    loginRequestCode({
                      phoneNumber: phoneNumber[0] !== 0 ? `+${phoneNumber}` : phoneNumber,
                      onSuccess: (res) => {
                        setChangeScreen(true);
                      },
                    });
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loginRequestCode: (payload) => dispatch(loginRequestCode(payload)),
  loginSendCode: (payload) => dispatch(loginSendCode(payload)),
});

export default connect(null, mapDispatchToProps)(Home);
