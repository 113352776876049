export const StatisticsStats = {
  GET_STATISTICS_SUCCESS: "statistics/GET_STATISTICS_SUCCESS",
  GET_STATISTICS_SESSIONS: "statistics/GET_STATISTICS_SESSIONS",
  GET_REGISTRATIONS:"statistics/GET_REGISTRATIONS",
  GET_PARTICIPANTS:"statistics/GET_PARTICIPANTS",
  GET_PAYMENTS:"statistics/GET_PAYMENTS",

  GET_VISITS_BY_DAY:"statistics/GET_VISITS_BY_DAY"
};

export const getStatisticsSessions = (payload) => ({
  type: StatisticsStats.GET_STATISTICS_SESSIONS,
  ...payload
})
export const getStatisticsSuccess = (payload) => ({
  type: StatisticsStats.GET_STATISTICS_SUCCESS,
  payload
})
export const getRegistrationsStats = (payload) => ({
  type:StatisticsStats.GET_REGISTRATIONS,
  payload
})
export const getParticipantsStats = (payload) => ({
  type:StatisticsStats.GET_PARTICIPANTS,
  payload
})
export const getPaymentsStats = (payload) => ({
  type:StatisticsStats.GET_PAYMENTS,
  payload
})
export const getVisitsByDay = (payload) => ({
  type: StatisticsStats.GET_VISITS_BY_DAY,
  ...payload
})