/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import Statistics from "../../components/Statistics";

const StatisticsPage = () => {
  return (
    <div className="screen-statistics-container row" style={{height:"93.8%"}}>
      <div className="col">
        <Statistics.Sessions />
        <Statistics.Month />
      </div>
      <div className="col" style={{minWidth:"35vw"}}>
        <Statistics.Registrations />
        <Statistics.Participants />
      </div>
      <div className="col">
        <Statistics.Payments/>
      </div>
    </div>
  );
};

export default StatisticsPage;
