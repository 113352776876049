/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import moment from "moment";

const DateInput = ({
  label,
  value,
  setValue,
  compulsory,
  style,
  inputStyle,
  disabled,
}) => {
  return (
    <div className="input-container" style={style}>
      <label style={{ display: "flex", width: "100%", fontSize: "13px" }}>
        {label}{" "}
        {compulsory && (
          <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
        )}
      </label>
      <div
        className={`prefix-input-container ${disabled && "disabled"}`}
        style={inputStyle}
      >
        <input
          className={`inner-input`}
          style={{ width: "98%" }}
          type="date"
          min={"1930-01-01"}
          max={new Date().toISOString().split("T")[0]}
          placeholder={"yyyy-mm-dd"}
          disabled={disabled}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export default DateInput;
