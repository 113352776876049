/* eslint-disable */
import { ResultsTypes } from "../actions";

const initialState = {
  categories: [],
  categoryRecords: [],
  autoCompleteMembers: [],
};

const results = (state = initialState, { type, payload }) => {
  switch (type) {
    case ResultsTypes.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: payload,
      };
    case ResultsTypes.GET_CATEGORY_RECORDS_SUCCESS:
      return {
        ...state,
        categoryRecords: payload._scoreboards,
      };
    case ResultsTypes.GET_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        autoCompleteMembers: payload,
      };
    default:
      return state;
  }
};

export default results;
