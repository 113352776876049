import { StatisticsStats } from "../actions";

const initialState = {
  sessions: { loading: false, period: "all" },
  visits: { loading: false, days: [] },
  registrations: { loading: false, period: "all" },
  participants: { loading: false, period: "all" },
  payments: { loading: false, period: "all" },
};

const statistics = (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case StatisticsStats.GET_STATISTICS_SUCCESS:
      if (payload.docs && payload.page && payload.page > 1)
        return {
          ...state,
          [payload.type]: {
            ...(state?.[payload.type] || {}),
            ...payload,
            docs: [...(state?.[payload.type]?.docs || {}), ...payload.docs],
          },
        };
      else
        return {
          ...state,
          [payload.type]: { ...(state?.[payload.type] || {}), ...payload },
        };
    default:
      return state;
  }
};

export default statistics;
