/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { listUsers } from "../../actions";
import Button from "../../components/Button/Button";
import { toast } from "react-toastify";
import { history } from "../../config/stores";
import UsersGrid from "../../components/Grids/UsersGrid";

const Users = ({users,listUsers}) => {

  useEffect(() => {
    listUsers()
  },[])
  return (
    <div className="main-body">
      <div className="shadow-body" style={{height:"unset"}}>
        <div className="shadow-body-header">
          <h3 className="shadow-body-title">USERS</h3>
          <Button
            text="ADD"
            green
            style={{ width: "8%"}}
            onClick={() => history.push("/add-user")}
          />
        </div>
        <UsersGrid listUsers={listUsers} users={users} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  users: state.login.users,
});

const mapDispatchToProps = (dispatch) => ({
  listUsers: (payload) => dispatch(listUsers(payload)),
  //loginSendCode: (payload) => dispatch(loginSendCode(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
