/* eslint-disable */
import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { logOut } from "../../actions";
import "./styles.scss";
import { User } from "../../utilites/User";

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        document.getElementById("drowdown-content").style.display = "none";
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = ({ ref, logOut }) => {
  const location = useLocation().pathname;
  const [showDropdown, setShowDropdown] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className="header-container">
      <div className="left-header-container">
        <div className="header-logo" onClick={() => history.push("/")} />
      </div>

      {User.isAdmin && (
        <div className="middle-header-container">
          <div
            className={`header-element ${location === "/" && "selected"} 
            ${location === "/locations/add" && "selected"} ${location.includes(`/location`) && "selected"}`}
            onClick={() => history.push("/")}
          >
            <div
              className={`header-inner-element ${location === "/" && "selected"} 
                ${location === "/locations/add" && "selected"} ${location.includes(`/location`) && "selected"} `}
            >
              REGISTRATIONS
            </div>
          </div>
          <div
            className={`header-element ${location === "/results" && "selected"}
            ${location === "/groups/add" && "selected"} ${location === `/group/${0 - 9}` && "selected"}`}
            onClick={() => history.push("/results")}
          >
            <div
              className={`header-inner-element ${location === "/results" && "selected"}
                ${location === "/groups/add" && "selected"} ${location === `/group/${0 - 9}` && "selected"}`}
            >
              RESULTS
            </div>
          </div>

          <div
            className={`header-element ${location === "/statistics" && "selected"}
            ${location === "/voucher/add" && "selected"} `}
            onClick={() => history.push("/statistics")}
          >
            <div
              className={`header-inner-element ${location === "/statistics" && "selected"}
                ${location === "/voucher/add" && "selected"}`}
            >
              STATISTICS
            </div>
          </div>
        </div>
      )}

      <div className="right-container">
        <div
          className={`dropdown ${showDropdown && "show"}`}
          onClick={() => {
            if (showDropdown) setShowDropdown(false);
            else setShowDropdown(true);
          }}
        ></div>
        {showDropdown && (
          <>
            <div className="dropdown-content" ref={wrapperRef} id="drowdown-content">
              {User.isAdmin && (
                <div className="profile">
                  <div className="text">Profile and settings</div>
                  <div
                    className="exit"
                    onClick={() => {
                      setShowDropdown(false);
                    }}
                  ></div>
                </div>
              )}

              <div className="info">
                <div className="name">{User.getUser().fullName}</div>
                <div className="phone-number">{User.getUser().phoneNumber}</div>
              </div>
              {User.getRole() === "admin" && (
                <div className="settings">
                  <div className="settings-text">Настройки потребители</div>
                  <div
                    className="green-arrow"
                    onClick={() => {
                      setShowDropdown(false);
                      history.push("/users");
                    }}
                  ></div>
                </div>
              )}

              {User.isAdmin && (
                <div
                  className="settings"
                  onClick={() => {
                    document.getElementById("drowdown-content").style.display = "none";
                    history.push("/users");
                  }}
                >
                  <div className="settings-text">Users</div>
                  <div className="green-arrow"></div>
                </div>
              )}

              <div
                className="log-out"
                onClick={() => {
                  logOut();
                  history.push("/login");
                }}
              >
                <div className="log-out-text">Log out</div>
                <div className="red-arrow"></div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  token: state.login.token,
});
const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
});
export default connect(null, mapDispatchToProps)(Header);
