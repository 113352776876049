import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { StatisticsStats } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";

const date = new Date();

export const getStatisticsSessionsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_STATISTICS_SESSIONS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/stats/visits`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "sessions",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

  export const getVisitsByDayEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_VISITS_BY_DAY),
    switchMap(({  onSuccess }) =>
      ajax({
        url: `${API_URL}/stats/monthly-visits?date=${date.toISOString()}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "visits",
                loading: false,
                days:[...response.payload],
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

  export const getRegistrationStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_REGISTRATIONS),
    switchMap(({ onSuccess }) =>
      ajax({
        url: `${API_URL}/stats/registrations`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "registrations",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );

  export const getParticipantsStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_PARTICIPANTS),
    switchMap(({ payload, onSuccess }) =>
      ajax({
        url: `${API_URL}/stats/participants`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "participants",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );
  export const getPaymentsStatsEpic = (action$) =>
  action$.pipe(
    ofType(StatisticsStats.GET_PAYMENTS),
    switchMap(({ payload, onSuccess }) =>
      ajax({
        url: `${API_URL}/stats/payments`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) =>
          ActionsObservable.create((obs) => {
            
            obs.next({
              type: StatisticsStats.GET_STATISTICS_SUCCESS,
              payload: {
                type: "payments",
                loading: false,
                ...response.payload,
              },
            });
            if (onSuccess) onSuccess(response);
            obs.complete();
          })
        ),
        catchError((err) =>
          ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          })
        )
      )
    )
  );