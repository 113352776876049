/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import TextInput from "../TextInput/TextInput";
import PhoneInput from "../PhoneInput/PhoneInput";

const UserForm = ({ edit, name, setName, phone, setPhone }) => {
  return (
    <div className="user-form-container">
      <TextInput
        value={name}
        setValue={setName}
        label={"First and last name"}
        compulsory
        style={{ width: "25%", padding: "1vw" }}
        inputStyle={{ height: "40px" }}
        disabled={!edit}
      />
      <PhoneInput
        value={phone}
        setValue={setPhone}
        compulsory
        labelStyle={{ width: "100%", display: "flex" }}
        style={{ width: "100%", marginTop: "1%" }}
        inputStyle={{ height: "40px", width: "100%" }}
        disabled={!edit}
      />
    </div>
  );
};

export default UserForm;
