import React, { useRef } from "react";
import { uploadFiles } from "../../utilites/HelperFunctions";
import "./styles.scss";

const UploadInput = ({ file, label, compulsory, disabled, style, className = "", inputProps, onChange = () => {} }) => {
  const uploadRef = useRef();

  const handleUpload = async ({ target: { files } }) => {
    const uploaded = await uploadFiles(files);
    if (onChange)
      onChange(
        uploaded.map(({ location }, i) => ({
          address: encodeURI(location),
          name: files[i].name,
        }))
      );
  };

  return (
    <div className="input-container upload" style={style}>
      {label &&
      <label style={{ display: "flex", width: "100%", fontSize: "16px" }}>
        {label}{" "}
        {compulsory && (
          <span className="compulsory" style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
        )}
      </label>}

      {file?.length ?
      <div className='file-wrapper'>
       <div className='file-row flex'>
         <div className='file-name'>{file || 'file.pdf'}</div>
         {!disabled && <img src={require('../../assets/icons/close.svg')} onClick={() => onChange([])} />}
       </div>
      </div> :
      <div
        className={`upload-button-container ${className}`}
        onClick={() => uploadRef.current.click()}
      >
        <p>Upload</p>
        <span>
          <img src={require("../../assets/icons/upload.svg")} />
        </span>
        <input
          ref={uploadRef}
          style={{ display: "none" }}
          type="file"
          accept="application/pdf"
          onChange={handleUpload}
          {...inputProps}
        />
      </div>}
    </div>
  );
};

export default UploadInput;
