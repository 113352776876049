/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { getUserByID, editUser, deleteUser, createUser } from "../../actions";
import Button from "../../components/Button/Button";
import UserForm from "../../components/Forms/UserForm";
import { toast } from "react-toastify";
import { history } from "../../config/stores";

const AddUser = ({ createUser }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");

  return (
    <div className="main-body">
      <div className="shadow-header">
        <div className="head-cont">
          <div
            className="close"
            onClick={() => {
              history.push("/users");
            }}
          />
          <h3 className="shadow-body-title">ADD NEW USER</h3>
        </div>
        <Button
          text={"SAVE"}
          style={{ width: "7%" }}
          green
          onClick={() => {
            const payload = {
              fullName: name,
              phoneNumber: `+${phone}`,
              onSuccess: () => {
                history.push("/users");
              },
            };
            createUser(payload);
          }}
        />
      </div>
      <div className="shadow-body" style={{ marginTop: "2%", padding: "0px",height:"unset" }}>
        <UserForm
          edit={true}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  createUser: (payload) => dispatch(createUser(payload)),
});
export default connect(null, mapDispatchToProps)(AddUser);
