/* eslint-disable */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { getCurrentRegistration, editMember, deleteMember, addMember, getHotels, getSportsInfo } from "../../actions";
import Button from "../../components/Button/Button";
import { history } from "../../config/stores";
import "rsuite/dist/rsuite.min.css";
import TeamMemberForm from "../../components/Forms/TeamMemberForm";
import { Switch } from "@blueprintjs/core";
import moment from "moment";
import { toast } from "react-toastify";
import Select from "react-select";
import { User } from "../../utilites/User";

const EventButtons = ({ label, payload, setPayload, field, array, updateEquipment }) => {
  return (
    <div
      className="input-container"
      style={{
        width: window.innerWidth < 1400 ? "47%" : "40%",
        padding: "0px",
        marginTop: "1%",
      }}
    >
      <label
        style={{
          width: "100%",
          fontSize: "13px",
          marginBottom: "1%",
        }}
      >
        {label}
        {field === "competitions" && <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>}
        <div className="flex-container full-width wrap" style={{ marginTop: "1%" }}>
          {array.map((el, i) => {
            return (
              <Button
                key={i}
                white
                selected={payload?.[field]?.includes(el)}
                text={el}
                onClick={() => {
                  if (payload?.[field].includes(el)) {
                    const newPayload = payload?.[field].slice(0);
                    if (field === "competitions") updateEquipment(newPayload.filter((comp) => comp !== el));
                    setPayload({
                      ...payload,
                      [field]: newPayload.filter((comp) => comp !== el),
                    });
                  } else {
                    const newPayload = payload?.[field]?.slice(0);
                    newPayload.push(el);
                    if (field === "competitions") updateEquipment(newPayload);
                    setPayload({ ...payload, [field]: newPayload });
                  }
                }}
                style={{
                  marginTop: "1%",
                  height: "30px",
                  marginRight: "3%",
                  whiteSpace: "nowrap",
                }}
              />
            );
          })}
        </div>
      </label>
    </div>
  );
};

const EventButtonsForObject = ({ label, payload, setPayload, field, array }) => {
  return (
    <div
      className="input-container"
      style={{
        width: window.innerWidth < 1400 ? "37%" : "30%",
        padding: "0px",
        marginTop: "1%",
      }}
    >
      <label
        style={{
          width: "100%",
          fontSize: "13px",
          marginBottom: "1%",
        }}
      >
        {label}
        <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
        <div className="flex-container full-width" style={{ marginTop: "1%" }}>
          {array.map((el, i) => {
            return (
              <Button
                key={i}
                white
                selected={payload?.[field]?.type === el?.type}
                text={el?.type}
                onClick={() => {
                  if (payload?.[field]?.type === el?.type) {
                    setPayload((prevState) => {
                      return { ...prevState, [field]: "" };
                    });
                  } else {
                    setPayload((prevState) => {
                      return { ...prevState, [field]: el };
                    });
                  }
                }}
                style={{
                  marginTop: "1%",
                  height: "30px",
                  marginRight: "3%",
                  whiteSpace: "nowrap",
                }}
              />
            );
          })}
        </div>
      </label>
    </div>
  );
};

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 30 * 0.75,
    height: 40,
    display: "flex",
    border: "1px solid gray",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SelectDropdown = ({ label, optionsArray, payload, setPayload, field }) => {
  return (
    <div className="input-container" style={{ width: "30%", padding: "0px" }}>
      <label
        className="flex-container full-width"
        style={{
          fontSize: "13px",
          marginBottom: "1%",
        }}
      >
        {label}
        <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
      </label>
      <Select
        options={optionsArray.map((opt) => {
          return { label: opt.name, value: opt.name };
        })}
        value={{ value: payload?.[field], label: payload?.[field] }}
        styles={customStyles}
        components={{ IndicatorSeparator: () => null }}
        onChange={(e) => {
          setPayload({ ...payload, [field]: e.value, roomType: {} });
        }}
      />
    </div>
  );
};

const TeamMemberAction = ({ getCurrentRegistration, editMember, addMember, deleteMember, getHotels, getSportsInfo, hotels, sportsInfo }) => {
  const reg_ID = window.location.href.split("/")[4];
  const member_ID = window.location.href.split("/")[5];
  const [payload, setPayload] = useState({
    isTeamLeader: false,
    fullName: "",
    birthdate: "",
    address: "",
    gender: "",
    email: "",
    nationality: "",
    height: null,
    shoeSize: null,
    shirtSize: null,
    competitions: [],
    equipment: [],
    hotel: "",
    roomType: "",
  });

  const [availableEquipment, setAvailableEquipment] = useState([]);

  useEffect(() => {
    if (member_ID) {
      getCurrentRegistration({
        _id: reg_ID,
        onSuccess: (res) => {
          const curMember = res?.members?.filter((mem) => mem?._id === member_ID)[0];
          setPayload({
            isTeamLeader: curMember?.isTeamLeader,
            fullName: curMember?.fullName,
            birthdate: moment(curMember?.birthdate).format("YYYY-MM-DD"),
            address: curMember?.address,
            gender: curMember?.gender,
            email: curMember?.email,
            nationality: curMember?.nationality,
            height: curMember?.height,
            shoeSize: curMember?.shoeSize,
            shirtSize: curMember?.shirtSize,
            competitions: curMember?.competitions,
            equipment: curMember?.equipment,
            hotel: curMember?.hotel,
            roomType: {
              type: curMember?.singleRoomCount
                ? "single"
                : curMember?.doubleRoomCount
                ? "double"
                : curMember?.tripleRoomCount
                ? "triple"
                : curMember?.quadrupleRoomCount
                ? "quadruple"
                : null,
            },
          });
          getSportsInfo({
            onSuccess: (res) => {
              const availableEq = res?.payload
                ?.filter((sport) => curMember?.competitions?.includes(sport.competition))
                ?.map((sport) => sport.equipment);
              setAvailableEquipment(availableEq);
            },
          });
        },
      });
      getHotels();
    } else {
      getHotels();
      getSportsInfo();
    }
  }, []);

  console.log(User.isAdmin);

  return (
    <div className="main-body" style={{ padding: "1vw" }}>
      <div className="shadow-header">
        <div className="head-cont">
          <div
            className="close arrow"
            onClick={() => {
              history.push(`/registration/${reg_ID}`);
            }}
          />
          <h3 className="shadow-body-title">{member_ID ? "EDIT TEAM MEMBER" : "ADD TEAM MEMBER"}</h3>
        </div>
        <div style={{ display: "flex", width: "15%", justifyContent: !member_ID && "flex-end" }}>
          {member_ID && (
            <Button
              text={"DELETE"}
              style={{ width: "50%", marginRight: "4%" }}
              redBorder
              onClick={() => {
                deleteMember({
                  id: member_ID,
                  onSuccess: () => {
                    toast.success("Member deleted successfully", {
                      autoClose: 3000,
                    });
                    history.push(`/registration/${reg_ID}`);
                  },
                });
              }}
            />
          )}

          <Button
            text={"SAVE"}
            style={{ width: "50%" }}
            green
            onClick={() => {
              if (member_ID) {
                const types = ["single", "double", "triple", "quadruple"];
                const roomToZero = types
                  .filter((type) => type !== payload.roomType?.type)
                  ?.map((type) => {
                    return {
                      [`${type}RoomCount`]: 0,
                    };
                  });
                editMember({
                  _id: member_ID,
                  payload: {
                    ...payload,
                    [`${payload.roomType?.type}RoomCount`]: 1,
                    ...roomToZero[0],
                    ...roomToZero[1],
                    ...roomToZero[2],
                    roomType: undefined,
                  },
                  onSuccess: () => {
                    toast.success("Member edited successfully", {
                      autoClose: 3000,
                    });
                    history.push(`/registration/${reg_ID}`);
                  },
                });
              } else {
                const types = ["single", "double", "triple", "quadruple"];
                const roomToZero = types
                  .filter((type) => type !== payload.roomType?.type)
                  ?.map((type) => {
                    return {
                      [`${type}RoomCount`]: 0,
                    };
                  });
                addMember({
                  registrationId: reg_ID,
                  ...payload,
                  roomType: undefined,
                  [`${payload.roomType?.type}RoomCount`]: 1,
                  ...roomToZero[0],
                  ...roomToZero[1],
                  ...roomToZero[2],
                  roomType: undefined,
                  onSuccess: () => {
                    toast.success("Member added successfully", {
                      autoClose: 3000,
                    });
                    history.push(`/registration/${reg_ID}`);
                  },
                });
              }
            }}
          />
        </div>
      </div>
      <div className="shadow-body" style={{ marginTop: "1%", padding: "1vw", height: "unset" }}>
        <h5 style={{ fontSize: "18px" }}>GENERAL INFORMATION</h5>
        <TeamMemberForm payload={payload} setPayload={setPayload} />
        {User.isAdmin === true && (
          <>
            <Switch
              checked={payload.isTeamLeader}
              onChange={() => {}}
              onClick={() => {
                payload.isTeamLeader ? setPayload({ ...payload, isTeamLeader: false }) : setPayload({ ...payload, isTeamLeader: true });
              }}
              labelElement={"THE USER IS THE TEAM LEADER"}
              innerLabelChecked="Yes"
              large={true}
              innerLabel="No"
              className="bp4-align-right"
              style={{
                marginTop: "1%",
                display: "flex",
                width: window.innerWidth < 1600 ? "32%" : "23%",
                justifyContent: "space-between",
                flexDirection: "row-reverse",
                fontSize: "18px",
                alignItems: "center",
              }}
            />
            <p style={{ fontSize: "13px" }}>
              The team leader will be contacted regarding organisational topics. Also he will be the one, responsible for the payments.{" "}
            </p>
          </>
        )}
      </div>
      <div className="shadow-body" style={{ margin: "1% 0%", padding: "1vw", height: "unset" }}>
        <h5 style={{ fontSize: "20px" }}>ACCOMODATION</h5>
        <div className="flex-container full-width align-center">
          <SelectDropdown payload={payload} setPayload={setPayload} label="Hotel type" field="hotel" optionsArray={hotels} />
          <EventButtonsForObject
            label="Room types"
            payload={payload}
            setPayload={setPayload}
            field={"roomType"}
            array={payload.hotel ? hotels?.filter((hotel) => hotel?.name === payload?.hotel)?.[0]?.roomTypes : []}
          />
        </div>
      </div>
      <div className="shadow-body" style={{ padding: "1vw", height: "unset" }}>
        <h5 style={{ fontSize: "20px" }}>EVENT INFORMATION</h5>
        <div className="flex-container full-width">
          <EventButtons
            label="Competing in"
            payload={payload}
            setPayload={setPayload}
            field={"competitions"}
            additionalSet={setAvailableEquipment}
            additionalField={sportsInfo}
            array={sportsInfo.map((sport) => sport.competition)}
            updateEquipment={(res) => {
              const availableEq = sportsInfo?.filter((sport) => res?.includes(sport.competition))?.map((sport) => sport.equipment);
              setAvailableEquipment(availableEq);
            }}
          />
          <EventButtons
            label="Renting out equipment"
            payload={payload}
            setPayload={setPayload}
            field={"equipment"}
            array={availableEquipment?.flat(1)?.filter((eq, i) => availableEquipment?.flat(1).indexOf(eq) === i)}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  currentRegistration: state.regs.currentRegistration,
  hotels: state.regs.hotels,
  sportsInfo: state.regs.sportsInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentRegistration: (payload) => dispatch(getCurrentRegistration(payload)),
  getHotels: () => dispatch(getHotels()),
  getSportsInfo: (payload) => dispatch(getSportsInfo(payload)),
  editMember: (payload) => dispatch(editMember(payload)),
  deleteMember: (payload) => dispatch(deleteMember(payload)),
  addMember: (payload) => dispatch(addMember(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamMemberAction);
