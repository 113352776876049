/* eslint-disable */
import React, { useRef } from "react";
import "./styles.scss";
import { Scrollbars } from "react-custom-scrollbars";
import moment from "moment";
import { history } from "../../config/stores";
import { Popover, Whisper } from "rsuite";

const headerElements = [
  {
    name: "Status",
    query: { filter: "status", options: [] },
  },
  {
    name: "Date of registration",
    query: { filter: "createdAt", options: [] },
  },
  {
    name: "Team",
    query: { filter: "team", options: [] },
  },
  {
    name: "Team leader",
    query: { filter: "leader", options: [] },
  },
  {
    name: "Country",
    query: { filter: "Country", options: [] },
  },
  {
    name: "Team email",
    query: { filter: "Team email", options: [] },
  },
  {
    name: "Participants",
    query: { filter: "Participants", options: [] },
  },
  {
    name: "Total Amount",
    query: { filter: "First payment", options: [] },
  },
  {
    name: "First payment",
    query: { filter: "Second payment", options: [] },
  },
  {
    name: "Second payment",
    query: { filter: "Second payment", options: [] },
  },
];

const RegistrationsGrid = ({ regs, list, curPage, setCurPage, totalPages }) => {
  const tableRef = useRef();
  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 0; // how much px from bottom
    const t = (Math.round(scrollTop) + pad) / (scrollHeight - clientHeight); // must be at least 1.0
    if (t >= 0.97 && totalPages >= curPage) {
      tableRef.current.scrollTop(200);
      list({
        page: curPage,
        limit: 20,
      });
      setCurPage(curPage + 1);
    }
  };

  return (
    <Scrollbars style={{ height: "91%", width: "93vw", marginTop: "1%" }} onUpdate={handleUpdate} ref={tableRef} id={"scrollbar"}>
      <div className="table-header">
        {headerElements.map((header, i) => {
          return (
            <div className="header-cont" key={i} style={{ width: "10%" }}>
              <div className="header">{header.name}</div>
              {/*<BiChevronDown size={"1.3em"} cursor={"pointer"} /> */}
            </div>
          );
        })}
      </div>
      {regs.map((reg, i) => {
        return (
          <div className="table-body-row" key={i}>
            <div
              className="row-data smaller"
              onClick={() => {
                history.push(`/registration/${reg?._id}`);
              }}
            >
              <div
                className="status-bar"
                style={{
                  backgroundColor:
                    reg?.status === "waiting"
                      ? "#B0D1FA"
                      : reg?.status.includes("accepted")
                      ? "#00e879"
                      : reg?.status === "canceled"
                      ? "#041B29"
                      : "#e5004c",
                  borderColor:
                    reg?.status === "waiting"
                      ? "#B0D1FA"
                      : reg?.status.includes("accepted")
                      ? "#00e879"
                      : reg?.status === "canceled"
                      ? "#041B29"
                      : "#e5004c",
                }}
              />
              {reg?.status}
              {reg?.status === "canceled" && (
                <Whisper
                  placement="bottomStart"
                  trigger="hover"
                  controlId="control-id-hover"
                  speaker={
                    <Popover>
                      <p style={{ textTransform: "capitalize", paddingBottom: "2px" }}>Reason</p>
                      <b style={{ textTransform: "capitalize" }}>{reg?.reason}</b>
                    </Popover>
                  }
                >
                  <div className="info-icon" />
                </Whisper>
              )}
            </div>
            <div className="row-data">{moment(reg?.createdAt).format("DD.MM.YYYY")}</div>
            <div className="row-data">{reg.team || "---"}</div>
            <div className="row-data">{reg?.members?.filter((member) => member?.isTeamLeader === true)[0]?.fullName}</div>
            <div className="row-data">{reg.country || "---"}</div>
            <div className="row-data">
              {reg?.teamEmail || "---"}
            </div>
            <div className="row-data">{reg?.members?.length}</div>
            <div className="row-data">{reg?.totalAmount || "---"}</div>
            <div className="row-data">{reg?.firstPayment?.isPaid ? "Yes" : "No"}</div>
            <div className="row-data">{reg?.secondPayment?.isPaid ? "Yes" : "No"}</div>
          </div>
        );
      })}
    </Scrollbars>
  );
};
export default RegistrationsGrid;
