export const authTypes = {
  LOGIN_REQUEST_CODE: "login/LOGIN_REQUEST_CODE",
  LOGIN_SEND_CODE: "login/LOGIN_SEND_CODE",

  LOGIN_SUCCESS: "login/LOGIN_SUCCESS",
  LOGIN_SUCCESS_LOCAL_STORAGE: "login/LOGIN_SUCCESS_LOCAL_STORAGE",

  LOG_OUT: "login/LOG_OUT",
  LOG_OUT_SUCCESS: "login/LOG_OUT_SUCCESS",

};

export const loginRequestCode = (payload) => ({
  type: authTypes.LOGIN_REQUEST_CODE,
  payload,
});

export const loginSendCode = (payload) => ({
  type: authTypes.LOGIN_SEND_CODE,
  payload,
});


export const loginSuccessFromLocalStorage = (payload) => ({
  type: authTypes.LOGIN_SUCCESS_LOCAL_STORAGE,
  payload,
});

export const logOut = () => ({
  type: authTypes.LOG_OUT,
});
