import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { RegistrationsTypes } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const listRegistrationsEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.REGISTRATIONS_LIST),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/search`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: RegistrationsTypes.REGISTRATIONS_LIST_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getCurrentRegistrationEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.GET_CURRENT_REGISTRATION),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/${payload._id}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.next({
              type: RegistrationsTypes.GET_CURRENT_REGISTRATION_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const changeRegistrationStatusEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.CHANGE_REGISTRATION_STATUS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/${payload._id}/status`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          status: payload.status,
          reason: payload.reason,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const updateRegistrationEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.UPDATE_REGISTRATION),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/${payload._id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          team: payload.team,
          teamEmail: payload.teamEmail,
          country: payload.country,
          hotel: payload.hotel,
          singleRoomCount: payload.singleRoomCount,
          doubleRoomCount: payload.doubleRoomCount,
          tripleRoomCount: payload.tripleRoomCount,
          quadrupleRoomCount: payload.quadrupleRoomCount,
          firstPayment: payload.firstPayment,
          secondPayment: payload.secondPayment,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            toast.error(err?.response?.message);
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const editMemberEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.EDIT_MEMBER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/members/${payload._id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify({ ...payload.payload }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message);
            obs.complete();
          });
        })
      );
    })
  );
export const addMemberEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.ADD_MEMBER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/members`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message);
            obs.complete();
          });
        })
      );
    })
  );
export const deleteMemberEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.DELETE_MEMBER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/members/${payload.id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message);
            obs.complete();
          });
        })
      );
    })
  );
export const getHotelsEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.GET_HOTELS),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/accommodations`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: RegistrationsTypes.GET_HOTELS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message);
            obs.complete();
          });
        })
      );
    })
  );
export const getSportsInfoEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.GET_SPORTS_INFO),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/competitions`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response);
            obs.next({
              type: RegistrationsTypes.GET_SPORTS_INFO_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message);
            obs.complete();
          });
        })
      );
    })
  );
export const exportExcelEpic = (action$) =>
  action$.pipe(
    ofType(RegistrationsTypes.EXPORT_EXCEL),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/registrations/export`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            if (payload?.onSuccess) payload.onSuccess(response);
            payload.onSuccess();
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message);
            obs.complete();
          });
        })
      );
    })
  );
