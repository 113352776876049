import { default as Sessions } from "./Sessions";
import { default as Month } from "./Month";
import { default as LoaderInline } from "./LoaderInline";
import { default as Horizontal } from "./Horizontal";
import { default as BarChart } from "./BarChart";
import { default as SelectPeriod } from "./SelectPeriod";
import { default as Pie } from './Pie'
import { default as Registrations } from "./Registrations"
import { default as Participants } from "./Participants";
import {default as Payments} from "./Payments"
const Statistics = {
  LoaderInline,
  Sessions,
  Horizontal,
  SelectPeriod,
  Month,
  BarChart,
  Registrations,
  Pie,
  Participants,
  Payments
};


export default Statistics;
