/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { loginRequestCode, loginSendCode } from "../../actions";
import { Scrollbars } from "react-custom-scrollbars";
import { BiChevronDown } from "react-icons/bi";
import { toast } from "react-toastify";
import moment from "moment";
import { history } from "../../config/stores";

const headerElements = [
  {
    name: "Name",
    query: { filter: "name", options: [] },
  },
  {
    name: "Phone number",
    query: { filter: "_phone", options: [] },
  },
  {
    name: "Added on",
    query: { filter: "_addedOn", options: [] },
  },
];

const UsersGrid = ({ listUsers, users }) => {
  const handleUpdate = (values) => {
    const { scrollTop, scrollHeight, clientHeight } = values; //get elements from object
    const pad = 1; // how much px from bottom
    const t = (scrollTop + pad) / (scrollHeight - clientHeight); // t should be greater than one if we are at the bottom of the container
    if (t > 1) {
    }
  };

  return (
    <Scrollbars
      style={{ height: "79vh", marginTop: "1%" }}
      onUpdate={handleUpdate}
    >
      <div className="table-header">
        {headerElements.map((header, i) => {
          return (
            <div className="header-cont" key={i}>
              <div className="header">{header.name}</div>
              <BiChevronDown size={"1.3em"} cursor={"pointer"} />
            </div>
          );
        })}
      </div>
      {users.map((user, i) => {
        return (
          <div className="table-body-row">
            <div className="row-data" onClick={() => history.push(`/user/${user?._id}`)}>
              {user.fullName}
            </div>
            <div className="row-data">{user.phoneNumber}</div>
            <div className="row-data">
              {moment(user.createdAt).format("DD.MM.YYYY")}
            </div>
          </div>
        );
      })}
    </Scrollbars>
  );
};

export default UsersGrid;
