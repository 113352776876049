/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

const Button = ({
  text,
  style,
  disabled,
  onClick,
  green,
  redBorder,
  red,
  white,
  dotted,
  selected,
  gray,
  textStyle,
}) => {
  return (
    <div
      className={`button-container ${selected && "selected"} ${
        disabled && "disabled"
      } ${green && "green"} ${redBorder && "redBorder"} ${red && "red"} ${
        white && "white"
      } ${dotted && "dotted"} ${gray && "gray"} `}
      style={style}
      onClick={onClick}
    >
      <b style={{ textTransform: "uppercase", marginTop: textStyle && "-7px" }}>
        {text}
      </b>
    </div>
  );
};
export default Button;
