import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { withRouter, Redirect } from "react-router-dom";
import { Home, OpenedUser, Registrations, OpenedRegistration, Users, StatisticsPage, TeamMemberAction } from "../../screens";
import { AddResult, Results } from "../../screens/Results";
import AddUser from "../../screens/Users/AddUser";
import { User } from "../../utilites/User";
import Header from "../Header/Header";

const AuthRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) => (!User.isAuthenticated ? <Route {...rest} /> : <Redirect to={{ pathname: "/", state: { from: location } }} />)}
    />
  );
};

const AdminRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        User.isAuthenticated ? (
          User.isAdmin ? (
            <Route {...rest} />
          ) : (
            <Redirect to={{ pathname: `/registration/${localStorage.getItem("registrationID")}`, state: { from: location } }} />
          )
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

const PrivateRoute = ({ children, ...rest }) => {
  return (
    <Route
      render={({ location }) =>
        !User.isAdmin ? (
          <Route {...rest} />
        ) : User.isAuthenticated ? (
          <Route {...rest} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: location } }} />
        )
      }
    />
  );
};

class Routes extends Component {
  render() {
    console.log(User.isAdmin);
    return (
      <div className="dashboard-wrap">
        {User.isAuthenticated ? <Header /> : ""}
        <Switch>
          <AuthRoute exact path="/login" component={Home} />
          <AdminRoute exact path="/" component={Registrations} />
          <AdminRoute exact path="/users" component={Users} />
          <AdminRoute path="/add-user" component={AddUser} />
          <AdminRoute path="/user" component={OpenedUser} />

          <PrivateRoute path="/registration" component={OpenedRegistration} />
          <PrivateRoute path="/edit-member" component={TeamMemberAction} />
          <PrivateRoute path="/add-member" component={TeamMemberAction} />

          <AdminRoute exact path="/results" component={Results} />
          <AdminRoute path="/add-record" component={AddResult} />
          <AdminRoute path="/edit-record" component={AddResult} />

          <AdminRoute path="/statistics" component={StatisticsPage} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { loading: state.starter.loading };
};

export default withRouter(connect(mapStateToProps)(Routes));
