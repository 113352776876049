import React, { useRef } from "react";
import "./styles.scss";

const LoginVerification = ({
  code,
  setCode,
  codeState,
  onSendAgain,
  disabled,
}) => {
  const inputRefs = useRef(new Array(4));
  return (
    <div className="login-verification-container">
      <p className="row code-invalid">
        {codeState === "invalid" && "Кодът е грешен"}
      </p>
      <div className="code-container">
        <div className="row code-container-inner">
          {new Array(4).fill("").map((v, i) => (
            <input
              onChange={() => {}}
              key={`input-${i}`}
              ref={(ref) => (inputRefs.current[i] = ref)}
              className={`digit-container ${
                codeState === "invalid" ? "invalid" : ""
              } ${codeState === "valid" ? "valid" : ""}`}
              type="text"
              maxLength="1"
              size="1"
              min="0"
              max="9"
              pattern="[0-9]{1}"
              value={code[i] || ""}
              onKeyDown={({ key, keyCode }) => {
                const newCode = code.slice();
                if (
                  (keyCode >= 48 && keyCode <= 57) ||
                  (keyCode >= 96 && keyCode <= 105)
                ) {
                  newCode.splice(i, 1, key);
                  if (i < code.length - 1) inputRefs.current[i + 1].focus();
                  else inputRefs.current[i].blur();
                } else if (keyCode === 8) {
                  newCode.splice(i, 1, "");
                  if (i > 0) inputRefs.current[i - 1].focus();
                }
                setCode(newCode);
              }}
            />
          ))}
        </div>
        <div
          className={`code-resend ${disabled && "disabled"}`}
          onClick={onSendAgain}
        >
          Resend code
        </div>
        <p className={`code-disabled ${disabled && "disabled"}`}>
          <span>
            СМС-ът ще пристигне скоро! От съображения за сигурност няма да може
            да заявите нов код в следващия 1 час.
          </span>
        </p>
      </div>
    </div>
  );
};

export default LoginVerification;
