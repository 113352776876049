/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { getUserByID, editUser, deleteUser } from "../../actions";
import Button from "../../components/Button/Button";
import UserForm from "../../components/Forms/UserForm";
import { toast } from "react-toastify";

const DeleteModal = ({ handleClose, handleAccept }) => {
  return (
    <div className={`delete-modal-container`}>
      <h3>DELETE USER</h3>
      <p>Are you sure you want to delete this user?</p>
      <div style={{ display: "flex",width:"99%",marginLeft:"3%" }}>
        <Button
          text={"CANCEL"}
          style={{ width: "48%", marginRight: "4%" }}
          white
          onClick={() => handleClose()}
        />
        <Button
          text={"DELETE USER"}
          style={{ width: "48%", marginRight: "3%" }}
          red
          onClick={() => handleAccept()}
        />
      </div>
    </div>
  );
};
export default DeleteModal;
