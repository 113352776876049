/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneInputComponent = ({
  value,
  setValue,
  labelStyle,
  compulsory,
  style,
  inputStyle,
  disabled
}) => {
  return (
    <div>
    <label style={labelStyle}>
        Phone number{" "}
        {compulsory && (
          <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
        )}
      </label>
    <PhoneInput
    country={'bg'}
    value={value}
    disabled={disabled}
    containerClass={ `phone-input-cont`}
    containerStyle={style}
    buttonClass={`btn-class ${disabled && "disabled"}`}
    inputClass={`phone-input ${disabled && "disabled"}`}
    inputStyle={inputStyle}
    onChange={phone => setValue(phone)}
  />
    </div>
  );
};
export default PhoneInputComponent;
