/* eslint-disable */
import * as XLSX from 'xlsx'
import moment from 'moment';
import React from 'react';
import { store } from "../config/stores"
import { Headers } from './Headers';
import S3FileUpload from 'react-s3';
import { nanoid } from 'nanoid'
import { s3Credentials } from "../config/settings";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const getPeriod = (period) => {
    switch (period) {
        case 'day':
            return { startDate: moment().subtract(24, 'hours'), endDate: moment() }
        case 'week':
            return { startDate: moment().subtract(7, 'days'), endDate: moment() }
        case 'month':
            return { startDate: moment().subtract(31, 'days'), endDate: moment() }
        default:
            return {}
    }
}

export const exportAppStatistics = async (period) => {
    store.dispatch(setLoadingOn())
    const wb = XLSX.utils.book_new()

    // total downloads
    const downloads = new Promise((resolve, reject) => {
        fetch(`${API}admin/stat/download`, {
            method: "POST",
            headers: Headers.get_auth(),
            body: JSON.stringify({ ...getPeriod(period) }),
        })
            .then((response) => response.json())
            .then(({ payload }) => {
                XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet([['Operation System', 'Total Downloads'], ...payload.map(({ operationSystem, totalDownloads }) => [operationSystem, totalDownloads])]), 'Total Downloads')
                resolve()
            })
            .catch((error) => reject(error))
    })
    

    try {
        await Promise.all([downloads,])
        XLSX.writeFile(wb, `statistics-${moment().format('DD-MM-YYYY')}.xlsx`)
        store.dispatch(setLoadingOff())
    } catch (error) { console.log(error) }

}

export const uploadFiles = (files) => {
    const promises = []
    Array.from(files).forEach((f) =>
        promises.push(S3FileUpload.uploadFile(new File([f], f?.name ? `${nanoid()}-${f?.name}` : nanoid(), {
            type: f?.type,
            lastModified: f?.lastModified || new Date(),
        }), s3Credentials))
    )
    return Promise.all(promises)
  }