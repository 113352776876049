/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";
import Select, { components } from "react-select";
import TextInput from "../TextInput/TextInput";
import UploadInput from "../UploadInput/UploadInput";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 25 * 0.75,
    height: 40,
    display: "flex",
    border: "1px solid gray",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SelectDropdown = ({
  label,
  optionsArray,
  getAutocomplete,
  field,
  payload,
  setPayload,
  disabled,
}) => {
  return (
    <div
      className="input-container"
      style={{ width: "25%", marginLeft: "-1.3%" }}
    >
      <label
        style={{
          display: "flex",
          width: "100%",
          fontSize: "13px",
          marginBottom: "1%",
        }}
      >
        {label}
        <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
      </label>
      <Select
        options={optionsArray.map((opt) => {
          return { label: opt.fullName, value: opt.fullName, team: opt.team };
        })}
        isDisabled={disabled}
        value={{
          value: payload?.[field],
          label: payload?.[field],
          team: payload?.team,
        }}
        styles={customStyles}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        onInputChange={(e) => {
          getAutocomplete(e);
        }}
        onChange={(e) => {
          setPayload({ ...payload, [field]: e.value, ["team"]: e.team });
        }}
      />
    </div>
  );
};

const AddRecordForm = ({
  edit,
  payload,
  setPayload,
}) => {

  return (
    <div className="user-form-container">
      <TextInput
        value={payload?.title || ''}
        setValue={(e) => {
          setPayload({ ...payload, ["title"]: e });
        }}
        label={"Name"}
        compulsory
        disabled={!edit}
        style={{ width: "65%", padding: "1vw" }}
        inputStyle={{ height: "45px", border: "1px solid gray", width: "100%" }}
      />

      <UploadInput
        inputProps={{ multiple: true }}
        label={"File"}
        compulsory
        disabled={!edit}
        style={{ paddingLeft: "0" }}
        file={payload?.url || ''}
        onChange={(res) => {
          if (Object?.keys(payload?.url)?.length) setPayload({ ...payload, url: '' });
          else setPayload({ ...payload, url: res[0]?.address });
        }}
      />
    </div>
  );
};

export default AddRecordForm;
