/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { getUserByID, editUser, deleteUser } from "../../actions";
import Button from "../../components/Button/Button";
import UserForm from "../../components/Forms/UserForm";
import ModalComponent from "../../components/ModalComponent/ModalComponent";
import DeleteModal from "../../components/ModalComponent/DeleteModal";
import { history } from "../../config/stores";

const OpenedUser = ({ selectedUser, getUserByID, editUser, deleteUser }) => {
  const user_ID = window.location.href.split("/")[4];
  const [edit, setEdit] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    getUserByID({
      id: user_ID,
      onSuccess: (res) => {
        setName(res.fullName);
        setPhone(res.phoneNumber);
      },
    });
  }, []);

  return (
    <div className="main-body">
      <div className="shadow-header">
        <div className="head-cont">
          <div
            className="close"
            onClick={() => {
              history.push("/users");
            }}
          />
          <h3 className="shadow-body-title">
            {edit ? "EDIT USER" : "PREVIEW USER"}
          </h3>
        </div>
        {edit ? (
          <div style={{ display: "flex", width: "15%" }}>
            <Button
              text={"DELETE"}
              style={{ width: "50%", marginRight: "4%" }}
              redBorder
              onClick={() => setOpenModal(true)}
            />
            <Button
              text={"SAVE"}
              style={{ width: "50%" }}
              green
              onClick={() => {
                editUser({
                  id: user_ID,
                  fullName: name,
                  phoneNumber: `+${phone}`,
                  onSuccess: () => {
                    setEdit(false);
                  },
                });
              }}
            />
          </div>
        ) : (
          <Button
            text={"EDIT"}
            style={{ width: "7%" }}
            green
            onClick={() => setEdit(true)}
          />
        )}
      </div>
      <div className="shadow-body" style={{ marginTop: "2%", padding: "0px",height:"unset" }}>
        <UserForm
          user={selectedUser}
          edit={edit}
          name={name}
          setName={setName}
          phone={phone}
          setPhone={setPhone}
        />
      </div>
      <ModalComponent
        open={openModal}
        children={
          <DeleteModal
            handleClose={() => setOpenModal(false)}
            handleAccept={() => {
              deleteUser({
                id: user_ID,
                onSuccess: () => {
                  history.push("/users");
                },
              });
            }}
          />
        }
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  selectedUser: state.login.selectedUser,
});

const mapDispatchToProps = (dispatch) => ({
  getUserByID: (payload) => dispatch(getUserByID(payload)),
  editUser: (payload) => dispatch(editUser(payload)),
  deleteUser: (payload) => dispatch(deleteUser(payload)),
});
export default connect(mapStateToProps, mapDispatchToProps)(OpenedUser);
