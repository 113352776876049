import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { periods } from "../../../config/constants";
import Statistics from "..";
import { getRegistrationsStats, getStatisticsSuccess } from "../../../actions";
import { getPeriod } from "../../../utilites/HelperFunctions";
import "./styles.scss";

const Registrations = () => {
  const dispatch = useDispatch();
  const {
    loading,
    period,
    totalRegistrationsCount,
    completedRegistrationsCount,
    incompletedRegistrationsCount,
  } = useSelector(({ statistics }) => statistics.registrations);

  const dates = useMemo(() => getPeriod(period), [period]);
  useEffect(() => {
    dispatch(getStatisticsSuccess({ type: "registrations", loading: true }));
    dispatch(getRegistrationsStats());
  }, [dispatch, dates]);

  return (
    <div className="statistics-downloads-container col">
      <div className="statistics-downloads-header row">
        <h2 style={{ paddingBottom: "0px" }}>
          <span style={{fontSize:"24px"}}>{totalRegistrationsCount}</span> <b style={{fontSize:"18px"}}>REGISTRATIONS</b>
        </h2>
        <div className="col">
          <div className="row" style={{ display: "flex" }}>
            <span>{periods.find(({ value }) => value === period).label}</span>
            <Popup
              className="popup-header-options-container"
              trigger={<div className="icon icon-settings" />}
              position="left"
            >
              {(close) => (
                <Statistics.SelectPeriod
                  value={period}
                  onChange={(period) => {
                    dispatch(
                      getRegistrationsStats({ type: "registrations", period })
                    );
                    close();
                  }}
                />
              )}
            </Popup>
          </div>
        </div>
      </div>
      <div className="statistics-sessions-secondery-header row"></div>
      <div className="statistics-downloads-content">
        {loading ? (
          <Statistics.LoaderInline />
        ) : (
          <Statistics.Pie
            data={[
              { value: "COMPLETED", label: completedRegistrationsCount },
              { value: "INCOMPLETED", label: incompletedRegistrationsCount },
            ]}
            type={"reg"}
          />
        )}
      </div>
    </div>
  );
};

export default Registrations;
