const PRODUCTION = true;

export const API_URL = PRODUCTION ? "https://gfl2024-api-prod.herokuapp.com" : "https://gfl2024-dev.herokuapp.com";

//DEV
// export const s3Credentials = {
//     bucketName: 'dev-fidweb',
//     dirName: 'gfl-admin',
//     region: 'eu-central-1',
//     accessKeyId: 'AKIA5L7PZ7W5LIVFASGG',
//     secretAccessKey: '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',
// }

//PROD
export const s3Credentials = {
    bucketName: 'gfl-2024',
    dirName: 'gfl-admin',
    region: 'eu-central-1',
    accessKeyId: 'AKIA5L7PZ7W5IVLR37WV',
    secretAccessKey: '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',
}