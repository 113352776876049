import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import Statistics from "..";
import { getParticipantsStats, getStatisticsSuccess } from "../../../actions";
import { periods } from "../../../config/constants";
import { getPeriod } from "../../../utilites/HelperFunctions";
import "./styles.scss";

const Participants = () => {
  const dispatch = useDispatch();
  const {
    loading,
    period,
    totalTeamsCount,
    totalCuntriesCount,
    totalMembersCount,
    totalCountByCountry,
  } = useSelector(({ statistics }) => statistics.participants);

  const dates = useMemo(() => getPeriod(period), [period]);
  useEffect(() => {
    dispatch(getStatisticsSuccess({ type: "participants", loading: true }));
    dispatch(getParticipantsStats({ payload: { ...dates } }));
  }, [dispatch, dates]);

  console.log(totalCountByCountry);

  return (
    <div className="statistics-sessions-container col">
      <div className="statistics-sessions-header row">
        <h2 style={{ paddingBottom: "0px" }}>
          <span style={{fontSize:"24px"}}>{totalMembersCount}</span> <b style={{fontSize:"18px"}}>PARTICIPANTS</b>
        </h2>
        <div className="col">
          <div className="row">
            <span>{periods.find(({ value }) => value === period).label}</span>
            <Popup
              className="popup-header-options-container"
              trigger={<div className="icon icon-settings" />}
              position="left"
            >
              {(close) => (
                <Statistics.SelectPeriod
                  value={period}
                  onChange={(period) => {
                    dispatch(
                      getStatisticsSuccess({ type: "sessions", period })
                    );
                    close();
                  }}
                />
              )}
            </Popup>
          </div>
        </div>
      </div>
      <div className="statistics-sessions-secondery-header row">
        <h3>
          <span>{totalTeamsCount}</span> <b>TEAMS</b>
        </h3>
        <h3>
          <span>{totalCuntriesCount}</span> <b>COUNTRIES</b>
        </h3>
      </div>
      <div className="statistics-sessions-content">
        <div className="statistics-sessions-section col">
          {loading ? (
            <Statistics.LoaderInline />
          ) : (
            <>
            {totalCountByCountry && <Statistics.Horizontal
                data={Object?.keys(totalCountByCountry)?.map((data) => {
                  return {
                    value: data,
                    label: totalCountByCountry?.[data],
                  };
                })}
              />}
          
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Participants;
