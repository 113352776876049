import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Popup from "reactjs-popup";
import { periods } from "../../../config/constants";
import Statistics from "..";
import { getPaymentsStats, getStatisticsSuccess } from "../../../actions";
import { getPeriod } from "../../../utilites/HelperFunctions";
import "./styles.scss";

const Payments = () => {
  const dispatch = useDispatch();
  const {
    loading,
    period,
    totalPaymentsCount,
    firstPayment,
    secondPayment,
    thirdPayment,
  } = useSelector(({ statistics }) => statistics.payments);

  const dates = useMemo(() => getPeriod(period), [period]);
  useEffect(() => {
    dispatch(getStatisticsSuccess({ type: "payments", loading: true }));
    dispatch(getPaymentsStats());
  }, [dispatch, dates]);

  return (
    <div className="statistics-downloads-container col">
      <div className="statistics-downloads-header row">
        <h2 style={{ paddingBottom: "0px" }}>
          <span>{totalPaymentsCount}</span> <b style={{fontSize:"18px"}}>PARTICIPANTS PAID</b>
        </h2>
        <div className="col">
          <div className="row" style={{ display: "flex" }}>
            <span>{periods.find(({ value }) => value === period).label}</span>
            <Popup
              className="popup-header-options-container"
              trigger={<div className="icon icon-settings" />}
              position="left"
            >
              {(close) => (
                <Statistics.SelectPeriod
                  value={period}
                  onChange={(period) => {
                    dispatch(getPaymentsStats({ type: "payments", period }));
                    close();
                  }}
                />
              )}
            </Popup>
          </div>
        </div>
      </div>
      <div className="statistics-sessions-secondery-header row"></div>
      <div className="statistics-downloads-content" style={{overflow:"hidden",border:"1px solid #B0D1FA",borderRadius:"7px",margin:"7px 0px",display:"block"}}>
      <h3 style={{fontSize:"16px",marginLeft:"1%"}}>FIRST PAYMENT DUE</h3>
        {loading ? (
          <Statistics.LoaderInline />
        ) : (
          <>
            <Statistics.Pie
              data={[
                { value: "PAID", label: firstPayment?.paid },
                {
                  value: "NOT PAID",
                  label: firstPayment?.unpaid,
                },
              ]}
              type={"payment"}
            />
          </>
        )}
      </div>
      <div className="statistics-downloads-content" style={{overflow:"hidden",border:"1px solid #B0D1FA",borderRadius:"7px",margin:"7px 0px",display:"block"}}>
      <h3 style={{fontSize:"16px",marginLeft:"1%"}}>SECOND PAYMENT DUE</h3>
        {loading ? (
          <Statistics.LoaderInline />
        ) : (
          <>
            <Statistics.Pie
              data={[
                { value: "PAID", label: secondPayment?.paid },
                {
                  value: "NOT PAID",
                  label: secondPayment?.unpaid,
                },
              ]}
              type={"payment"}
            />

          </>
        )}
      </div>
    </div>
  );
};

export default Payments;
