import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getVisitsByDay, getStatisticsSuccess } from "../../../actions";
import Statistics from "..";
import "./styles.scss";

const Month = () => {
  const dispatch = useDispatch();
  const { loading, days } = useSelector(
    ({ statistics }) => statistics.visits
  );

  useEffect(() => {
    dispatch(getVisitsByDay());
    dispatch(getStatisticsSuccess({ type: "visits", loading: true }));
  }, [dispatch]);

  return (
    <>
      <div className="statistics-month-container col">
        
        <div className="statistics-month-content">
          <div className="statistics-month-section">
            <h4 style={{fontSize:"16px"}}>VISITS BY DAY</h4>
            {loading ? (
              <Statistics.LoaderInline />
            ) : (
              <Statistics.BarChart
                color="#189AEA"
                data={Object.fromEntries(
                  days?.map((el) => [el.day, el.count]) || []
                )}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Month;
