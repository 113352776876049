import { combineReducers } from 'redux'
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from 'react-router-redux'

import starter from './starter'
import login from './auth'
import regs from "./registrations"
import results from './results'
import statistics from './statistics'

export default combineReducers({
  starter,
  login,
  regs,
  results,
  statistics,
  routerReducer
})
