const User = {
    isAuthenticated: localStorage.getItem('token') !== null,
    isAdmin: localStorage.getItem('role') === "admin",
    token: localStorage.getItem('token'),
    phoneNumber:localStorage.getItem('phoneNumber'),
    user: JSON.parse(localStorage.getItem('user')),
    //supportID: localStorage.getItem('customerSupportId'),
    role:localStorage.getItem('role'),
    getToken () { return User.token },
    getUser () { return User.user },
    getPhoneNumber () {return User.phoneNumber},
    //getSupportID () {return User.supportID},
    getRole() {return User.role},
    
    setUserKey (key, value) {
      return new Promise((resolve, reject) => {
        User.user[key] = value
        localStorage.setItem('user', JSON.stringify(User.user))
        resolve()
      })
    },
    /*setSupportID (id){
      localStorage.setItem("customerSupportId",id);
    },
   */
    authenticate(token, user) {
      return new Promise ((resolve, reject) => {
        User.isAuthenticated = true
        user.role === "admin" ? User.isAdmin = true : User.isAdmin = false
        User.token = token
        User.user = user
        User.phoneNumber = user.phoneNumber
        //User.customerSupportId = user.customerSupportId
        User.role = user.role
        localStorage.setItem('token', token)
        localStorage.setItem('user', JSON.stringify(user))
        localStorage.setItem('phoneNumber',user.phoneNumber);
        //localStorage.setItem('customerSupportId',user?.customerSupportId)
        localStorage.setItem('role',user.role)
        resolve()
      })
    },
    signout(cb) {
      return new Promise ((resolve, reject) => {
        User.isAuthenticated = false
        User.isAdmin = false
        User.token = null
        User.user = null
        User.phoneNumber = null
       // User.supportID = null
        //User.customerSupportId = null
        User.role = null
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('phoneNumber')
        //localStorage.removeItem("customerSupportID")
        //localStorage.removeItem("customerSupportId")
        localStorage.removeItem("role")
        resolve()
      })
    }
  }
  
  export { User }