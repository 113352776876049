import { combineEpics } from "redux-observable";
import * as starter from "./starter";
import * as auth from "./auth";
import * as users from "./users";
import * as registrations from "./registrations";
import * as results from "./results";
import * as statistics from "./statistics";

export const rootEpic = combineEpics(
  starter.loadingEpic,
  auth.loginRequestCodeEpic,
  auth.loginSendCodeEpic,
  auth.logOutEpic,
  users.createUserEpic,
  users.listUsersEpic,
  users.getUserByIDEpic,
  users.editUserEpic,
  users.deletUserEpic,
  registrations.listRegistrationsEpic,
  registrations.getCurrentRegistrationEpic,
  registrations.changeRegistrationStatusEpic,
  registrations.updateRegistrationEpic,
  registrations.editMemberEpic,
  registrations.deleteMemberEpic,
  registrations.addMemberEpic,
  registrations.getHotelsEpic,
  registrations.getSportsInfoEpic,
  registrations.exportExcelEpic,
  results.getCategoriesEpic,
  results.getCategoryRecordsEpic,
  results.addRecordEpic,
  results.editRecordEpic,
  results.deleteRecordEpic,
  results.getAutocompleteEpic,
  statistics.getStatisticsSessionsEpic,
  statistics.getVisitsByDayEpic,
  statistics.getRegistrationStatsEpic,
  statistics.getParticipantsStatsEpic,
  statistics.getPaymentsStatsEpic
);
