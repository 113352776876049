import { ofType, ActionsObservable } from "redux-observable";
import { switchMap, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
import { UsersTypes } from "../actions";
import { API_URL } from "../config/settings";
import { Headers } from "../utilites/Headers";
import { toast } from "react-toastify";

export const createUserEpic = (action$) =>
  action$.pipe(
    ofType(UsersTypes.CREATE_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/users`,
        method: "POST",
        headers: Headers.get_auth(),
        body: JSON.stringify(payload),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess();
            toast.success(
              `User with name: {${response.payload.fullName}} created successfully.`
            );
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message)
            obs.complete();
          });
        })
      );
    })
  );

export const listUsersEpic = (action$) =>
  action$.pipe(
    ofType(UsersTypes.LIST_USERS),
    switchMap(() => {
      return ajax({
        url: `${API_URL}/users`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            obs.next({
              type: UsersTypes.LIST_USERS_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const getUserByIDEpic = (action$) =>
  action$.pipe(
    ofType(UsersTypes.GET_USER_BY_ID),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/users/${payload.id}`,
        method: "GET",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            obs.next({
              type: UsersTypes.GET_USER_BY_ID_SUCCESS,
              payload: response.payload,
            });
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );

export const editUserEpic = (action$) =>
  action$.pipe(
    ofType(UsersTypes.EDIT_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/users/${payload.id}`,
        method: "PUT",
        headers: Headers.get_auth(),
        body: JSON.stringify({
          fullName: payload.fullName,
          phoneNumber: payload.phoneNumber,
        }),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            toast.success(
              `User with name {${response.payload.fullName}} updated successfully`
            );
            payload.onSuccess(response.payload);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            toast.error(err.response.message)
            obs.complete();
          });
        })
      );
    })
  );

export const deletUserEpic = (action$) =>
  action$.pipe(
    ofType(UsersTypes.DELETE_USER),
    switchMap(({ payload }) => {
      return ajax({
        url: `${API_URL}/users/${payload.id}`,
        method: "DELETE",
        headers: Headers.get_auth(),
      }).pipe(
        switchMap(({ response }) => {
          return ActionsObservable.create((obs) => {
            payload.onSuccess(response.payload);
            toast.success(`User deleted successfully.`);
            obs.complete();
          });
        }),
        catchError((err) => {
          return ActionsObservable.create((obs) => {
            console.log(err);
            obs.complete();
          });
        })
      );
    })
  );
