/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import { getUserByID, editUser, deleteUser } from "../../actions";
import Button from "../../components/Button/Button";
import UserForm from "../../components/Forms/UserForm";
import { toast } from "react-toastify";

const ModalComponent = ({ children, open }) => {
  return (
    <div className={`modal-component ${open && "show"} `}>
      {children}
    </div>
  );
};
export default ModalComponent;
