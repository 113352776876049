export const UsersTypes = {
  CREATE_USER: "users/CREATE_USER",

  GET_USER_BY_ID: "users/GET_USER_BY_ID",
  GET_USER_BY_ID_SUCCESS: "users/GET_USER_BY_ID_SUCCESS",

  LIST_USERS: "users/LIST_USERS",
  LIST_USERS_SUCCESS: "users/LIST_USERS_SUCCESS",

  EDIT_USER: "users/EDIT_USER",
  DELETE_USER: "users/DELETE_USER",
};

export const createUser = (payload) => ({
  type: UsersTypes.CREATE_USER,
  payload,
});

export const listUsers = (payload) => ({
  type: UsersTypes.LIST_USERS,
  payload,
});

export const getUserByID = (payload) => ({
  type: UsersTypes.GET_USER_BY_ID,
  payload,
});

export const editUser = (payload) => ({
  type: UsersTypes.EDIT_USER,
  payload,
});

export const deleteUser = (payload) => ({
  type: UsersTypes.DELETE_USER,
  payload,
});
