/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  addRecord,
  getAutocomplete,
  updateRecord,
  deleteRecord,
} from "../../actions";
import "./styles.scss";
import Button from "../../components/Button/Button";
import { history } from "../../config/stores";
import AddRecordForm from "../../components/Forms/AddRecordForm";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

const AddResult = ({
  addRecord,
  getAutocomplete,
  autoCompleteMembers,
  deleteRecord,
  updateRecord,
}) => {
  const scoreBoardID = window.location.href.split("/")[4];
  const location = useLocation();
  const [payload, setPayload] = useState({
    scoreboardId: scoreBoardID,
    title: "",
    url: "",
  });

  const [edit, setEdit] = useState(location.state ? false : true);

  useEffect(() => {
    if (location.state) {
      setPayload({
        title: location.state.title,
        url: location.state.url,
        resultsFileId: location.state._id,
      });
    }
  }, []);

  return (
    <div className="main-body">
      <div className="shadow-header">
        <div className="head-cont">
          <div className="close" onClick={() => history.push("/results")} />
          <h3 className="shadow-body-title">UPLOAD RESULTS</h3>
        </div>
        {location.state ? (
          <>
            {edit === true ? (
              <div style={{ display: "flex", width: "15%", gap: "15px" }}>
                <Button
                  text={"DELETE"}
                  style={{ width: "48%", marginRight: "2%" }}
                  redBorder
                  onClick={() => {
                    deleteRecord({
                      _id: payload?.resultsFileId,
                      onSuccess: () => {
                        toast.success("Record deleted successfully!", {
                          autoClose: 3000,
                        });
                        history.push("/results");
                      },
                    });
                  }}
                />
                <Button
                  text={"PUBLISH"}
                  style={{ width: "48%" }}
                  green
                  onClick={() => {
                    const finalPayload = {
                      ...payload,
                      onSuccess: () => {
                        toast.success("Record edited successfully!", {
                          autoClose: 3000,
                        });
                        history.push("/results");
                      },
                    };
                    updateRecord(finalPayload);
                  }}
                />
              </div>
            ) : (
              <Button
                text={"EDIT"}
                style={{ width: "7%" }}
                blue
                onClick={() => {
                  setEdit(true);
                }}
              />
            )}
          </>
        ) : (
          <Button
            text={"PUBLISH"}
            style={{ width: "7%" }}
            green
            onClick={() => {
              addRecord({
                ...payload,
                onSuccess: () => {
                  toast.success("Record added successfully");
                  history.push("/results");
                },
              });
            }}
          />
        )}
      </div>
      <div className="shadow-body" style={{ marginTop: "1%",height:"unset" }}>
        <h4 className="form-title">FILE DETAIL</h4>
        <AddRecordForm
          edit={edit}
          payload={payload}
          setPayload={setPayload}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  autoCompleteMembers: state.results.autoCompleteMembers,
});

const mapDispatchToProps = (dispatch) => ({
  addRecord: (payload) => dispatch(addRecord(payload)),
  updateRecord: (payload) => dispatch(updateRecord(payload)),
  deleteRecord: (payload) => dispatch(deleteRecord(payload)),
  getAutocomplete: (payload) => dispatch(getAutocomplete(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddResult);
