/* eslint-disable */
import React, { useState, useEffect, useRef, useMemo } from "react";
import "./styles.scss";
import TextInput from "../TextInput/TextInput";
import DateInput from "../DateInput/DateInput";
import Select from "react-select";
import { nationalities } from "../../utilites/Attributes";
import { shoeSizes } from "../../utilites/Attributes";
import { shirtSizes } from "../../utilites/Attributes";

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    color: "#0083E5", // Custom colour
    transition: "all .2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "white" : "black",
    backgroundColor: state.isSelected ? "#0083E5" : "white",
    padding: 10,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    width: (window.innerWidth / 100) * 20 * 0.75,
    height: 40,
    display: "flex",
    border: "1px solid gray",
    borderRadius: "7px",
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};

const SelectDropdown = ({ label, optionsArray, payload, setPayload, field }) => {
  return (
    <div className="input-container" style={{ width: "20%", marginLeft: "-1%", padding: "1vw" }}>
      <label
        style={{
          display: "flex",
          width: "100%",
          fontSize: "13px",
          marginBottom: "1%",
        }}
      >
        {label}
        <span style={{ marginLeft: "1%", color: "#E5004C" }}>*</span>
      </label>
      <Select
        options={optionsArray.map((opt) => {
          return { label: opt, value: opt };
        })}
        value={{ value: payload?.[field], label: payload?.[field] }}
        styles={customStyles}
        components={{ IndicatorSeparator: () => null }}
        onChange={(e) => {
          if (field.includes("shoeSize")) {
            setPayload({ ...payload, [field]: Number(e.label.split(" ")[0]) });
          } else setPayload({ ...payload, [field]: e.label });
        }}
      />
    </div>
  );
};

const TeamMemberForm = ({ payload, setPayload }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className="user-form-container">
        <TextInput
          value={payload.fullName}
          setValue={(e) => setPayload({ ...payload, fullName: e })}
          label={"First and last name"}
          compulsory
          style={{ width: "25%", padding: "1vw", marginLeft: "-1%" }}
          inputStyle={{ height: "40px" }}
          disabled={false}
        />
        <SelectDropdown payload={payload} setPayload={setPayload} label="Gender" field="gender" optionsArray={["male","female"]} />
        <DateInput
          value={payload.birthdate}
          setValue={(e) => {
            setPayload({ ...payload, birthdate: e });
          }}
          label={"Date of birth"}
          compulsory
          style={{ width: "15%", padding: "1vw" }}
          inputStyle={{ height: "40px" }}
          disabled={false}
        />
        <TextInput
          value={payload.address}
          setValue={(e) => setPayload({ ...payload, address: e })}
          label={"Address"}
          compulsory
          style={{ width: "25%", padding: "1vw" }}
          inputStyle={{ height: "40px" }}
          disabled={false}
        />
        <TextInput
          value={payload.email}
          setValue={(e) => setPayload({ ...payload, email: e })}
          label={"Email"}
          compulsory
          style={{ width: "25%", padding: "1vw" }}
          inputStyle={{ height: "40px" }}
          disabled={false}
        />
      </div>
      <div className="user-form-container">
        <SelectDropdown payload={payload} setPayload={setPayload} label="Nationality" field="nationality" optionsArray={nationalities} />
        <TextInput
          value={payload.height}
          setValue={(e) => setPayload({ ...payload, height: Number(e) })}
          label={"Height"}
          compulsory
          style={{ width: "18%", padding: "1vw" }}
          inputStyle={{ height: "40px" }}
          disabled={false}
          suffix={"cm"}
          number
        />
        <SelectDropdown payload={payload} setPayload={setPayload} label="Shoe size" field="shoeSize" optionsArray={shoeSizes} />
        <SelectDropdown payload={payload} setPayload={setPayload} label="T-shirt size" field="shirtSize" optionsArray={shirtSizes} />
      </div>
    </div>
  );
};

export default TeamMemberForm;
